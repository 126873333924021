import React from 'react';
import { useTranslation } from 'react-i18next';

import { Callout, CheckItem, MultiSelect, Spinner, Text } from '@bloobirds-it/flamingo-ui';

import styles from '../segmentationFilter.module.css';
import { useSegmentationFilter } from '../useSegmentationFilter';

export const TeamsSelector = ({ teamsList, isLoadingTeams }) => {
  const { selectedTeams, setSelectedTeams, isSmartEmail } = useSegmentationFilter();
  const { t } = useTranslation();

  const handleSelectTeam = teamId => {
    const currentSelectedTeams = selectedTeams ?? [];
    const isTeamSelected = currentSelectedTeams.some(team => team.id === teamId);
    if (isTeamSelected) {
      setSelectedTeams(currentSelectedTeams.filter(team => team.id !== teamId));
    } else {
      const newSelectedTeam = teamsList.find(team => team.id === teamId);
      if (newSelectedTeam) {
        setSelectedTeams([...currentSelectedTeams, newSelectedTeam]);
      }
    }
  };

  const selectedTeamIds = selectedTeams?.map(team => team.id);

  return (
    <div style={{ width: '100%' }}>
      <Text
        className={!isSmartEmail ? styles.teamFilter : styles.teamFilterSmart}
        size="s"
        weight="bold"
        color="peanut"
      >
        Teams
      </Text>
      {isLoadingTeams ? (
        <Spinner name={'loadingCircle'} size={24} />
      ) : teamsList?.length === 0 ? (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Callout variant="neutral" withoutIcon width="80%">
            <Text size="xs" color="softPeanut">
              {t('playbook.segmentationFilter.noTeams')}
            </Text>
          </Callout>
        </div>
      ) : (
        <div className={styles.selectorsWrapper}>
          <MultiSelect
            role="menu"
            placeholder={t('playbook.segmentationFilter.allTeams')}
            dropdownProps={{ zIndex: 20000 }}
            size="small"
            value={selectedTeamIds ? selectedTeamIds : []}
            width={'100%'}
            autocomplete
          >
            {teamsList?.map(value => (
              <CheckItem
                key={value.id}
                value={value.id}
                label={value.name}
                checked={selectedTeams?.some(team => team.id === value.id)}
                onClick={() => handleSelectTeam(value.id)}
              >
                {value.name}
              </CheckItem>
            ))}
          </MultiSelect>
        </div>
      )}
    </div>
  );
};
