import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

import { ActivityTimelineItem } from '@bloobirds-it/activity-timeline-item';
import { CopilotActivityContextProvider } from '@bloobirds-it/copilot';
import { Spinner, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import { useSyncBobjectStatus } from '@bloobirds-it/hooks';
import { InfoWarningSync } from '@bloobirds-it/misc';
import {
  ACTIVITY_DIRECTION,
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  DataModelResponse,
  GroupedLinkedInMessage,
} from '@bloobirds-it/types';
import { api, getTextFromLogicRole } from '@bloobirds-it/utils';
import clsx from 'clsx';

import { ActivityWithIAWrapper } from '../../components/activityWithAIWrapper/activityWithAIWrapper';
import { DeleteMessage } from '../../components/deleteMessage/deleteMessage';
import styles from './linkedInDetail.module.css';

export interface DetailsActivityProps {
  activity: Bobject;
  dataModel: DataModelResponse;
  items: GroupedLinkedInMessage[];
  totalMatching: number;
  fetchNextPage: () => void;
  isLoading: boolean;
  isBubble?: boolean;
  showInsights?: boolean;
  mutate: () => void;
  hasUserEditAll: boolean;
  onOpenEmailModal?: (text: string) => void;
}

export const LinkedInDetailedActivity = ({
  activity,
  dataModel,
  items,
  totalMatching,
  fetchNextPage,
  isLoading,
  mutate,
  isBubble,
  hasUserEditAll,
  onOpenEmailModal,
  showInsights,
}: DetailsActivityProps) => {
  const { t } = useTranslation();
  const { createToast } = useToasts();
  const ref = useRef();
  const { bobjectsSync } = useSyncBobjectStatus(items?.[0]?.id?.accountId, items);

  const hasNextPage = useMemo(() => {
    return !(items?.length === totalMatching);
  }, [items?.length, totalMatching]);

  if (!items || isLoading) {
    return (
      <div className={styles.loading}>
        <Spinner name="loadingCircle" />
      </div>
    );
  }

  const deleteMessage = async messageData => {
    try {
      await api.delete(`bobjects/${messageData?.id?.value}`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        data: {},
      });
      mutate();
      createToast({
        message: t('activityTimelineItem.item.deleteMessageConfirmToast'),
        type: 'success',
      });
    } catch (error) {
      createToast({
        message: t('common.somethingWentWrong'),
        type: 'error',
      });
      console.error('Error deleting message:', error);
    }
  };

  return (
    <CopilotActivityContextProvider activity={activity}>
      <ActivityWithIAWrapper
        activity={activity}
        isBubble={isBubble}
        regenerateAvailable
        onOpenEmailModal={onOpenEmailModal}
        showInsights={showInsights}
      >
        <ActivityTimelineItem
          activity={activity}
          key={activity?.id?.value}
          startDisplayDivider={false}
          endDisplayDivider={false}
          extended
          alternativeDescription
          activeHover={false}
          dataModel={dataModel}
        />
        <div className={styles._messages_wrapper} id="conversationContent" ref={ref}>
          <InfiniteScroll
            dataLength={items.length}
            hasMore={hasNextPage}
            next={fetchNextPage}
            inverse={true}
            height="100%"
            scrollThreshold={0.75}
            scrollableTarget="conversationContent"
            loader={
              <div className={styles.loading}>
                <Spinner name="dots" />
              </div>
            }
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
          >
            <div key={`${activity?.id?.value}-list`} className={styles._conversation_wrapper}>
              {items.map((data: any) => {
                const messageText = getTextFromLogicRole(
                  data,
                  ACTIVITY_FIELDS_LOGIC_ROLE.MESSAGE_BODY,
                );
                const messageDirection = getTextFromLogicRole(
                  data,
                  ACTIVITY_FIELDS_LOGIC_ROLE.DIRECTION,
                );

                const syncStatus = bobjectsSync?.find(
                  sync => sync?.bobjectId === data?.id?.objectId,
                )?.syncStatusOk;

                return (
                  <div key={data?.id?.value}>
                    {data?.messageDate?.isFirstOfDay && (
                      <div className={styles._date_separator}>
                        <Text color="softPeanut" size="s" align="center">
                          {data?.messageDate?.formattedDate}
                        </Text>
                      </div>
                    )}
                    <div
                      className={clsx(styles._message, {
                        [styles._message_incoming]:
                          messageDirection === ACTIVITY_DIRECTION.INCOMING,
                        [styles._message_outgoing]:
                          messageDirection === ACTIVITY_DIRECTION.OUTGOING,
                      })}
                    >
                      {messageDirection === ACTIVITY_DIRECTION.OUTGOING && hasUserEditAll && (
                        <div className={styles._delete_message_outgoing}>
                          <DeleteMessage
                            onClick={() => deleteMessage(data)}
                            isDisabled={false}
                            messageDirection={ACTIVITY_DIRECTION.OUTGOING}
                          />
                        </div>
                      )}
                      <div className={styles._message_body}>
                        <div
                          className={clsx(styles._svg_wrapper, {
                            [styles._svg_incoming]:
                              messageDirection === ACTIVITY_DIRECTION.INCOMING,
                            [styles._svg_outgoing]:
                              messageDirection === ACTIVITY_DIRECTION.OUTGOING,
                          })}
                        >
                          {syncStatus !== undefined && !syncStatus && (
                            <InfoWarningSync type={'message'} id={data?.id} />
                          )}
                        </div>
                        <Text size="s" className={styles._message_body}>
                          {messageText === 'undefined'
                            ? t('linkedInDetail.messageNotAvailable')
                            : messageText}
                        </Text>
                      </div>
                      {messageDirection === ACTIVITY_DIRECTION.INCOMING && hasUserEditAll && (
                        <div className={styles._delete_message_incoming}>
                          <DeleteMessage
                            onClick={() => deleteMessage(data)}
                            isDisabled={false}
                            messageDirection={ACTIVITY_DIRECTION.INCOMING}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      </ActivityWithIAWrapper>
    </CopilotActivityContextProvider>
  );
};
