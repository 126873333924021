import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useActiveUserSettings,
  useMessagingTemplates,
  useSuggestedTemplates,
  useUserHelpers,
} from '@bloobirds-it/hooks';
import {
  ExtensionHelperKeys,
  MessagingTemplate,
  PlaybookTab,
  templateTypes,
  UserHelperTooltipsKeys,
  UserRole,
} from '@bloobirds-it/types';
import { getLinkedInURL, getTemplateTypeButtons } from '@bloobirds-it/utils';
import { useDebounce } from 'use-debounce';

import { useMessagingContentActions } from '../../../hooks/useMessagingContentActions';
import { MessagingCard } from '../../playbookCard/playbookCard';
import { SnippetsTooltipBlock } from '../../snippetsTooltipBlock/snippetsTooltipBlock';
import { TemplatesBlock } from '../playbookFeed.types';
import { usePlaybookFeed } from '../usePlaybookFeed';
import { TemplateListDisplay } from './templateList/templateList';

export const MessagingContent = () => {
  const {
    selectedTab: tabSelected,
    activeBobject,
    segmentationValues,
    visibilityFilters,
    searchValue,
    stage,
    environment,
    selectedTeams,
  } = usePlaybookFeed();

  const { myTemplates, teamTemplates, isLoading } = useMessagingTemplates(
    {
      stage: stage,
      type: templateTypes[tabSelected],
      name: searchValue ? searchValue : null,
      visibility: visibilityFilters?.onlyPrivate ? 'PRIVATE' : null,
      onlyMine: visibilityFilters?.onlyMine,
      onlyOfficials: visibilityFilters?.onlyOfficial,
      onlyBattlecards: visibilityFilters?.onlyBattlecards,
      segmentationValues,
      teams: selectedTeams?.map(team => team.id),
    },
    'sidepeek-content-' + environment,
  );

  const suggestedTemplates = useSuggestedTemplates(activeBobject, tabSelected);

  return (
    <>
      {suggestedTemplates?.length > 0 && (
        <MessagingSubContent
          templatesBlock={TemplatesBlock.SuggestedTemplates}
          templates={{ messagingTemplates: suggestedTemplates, hasNextPage: false }}
          {...{ isLoading }}
        />
      )}
      {myTemplates?.messagingTemplates?.length > 0 && (
        <MessagingSubContent
          templatesBlock={TemplatesBlock.MyTemplates}
          templates={myTemplates}
          {...{ isLoading }}
        />
      )}
      <MessagingSubContent
        templatesBlock={TemplatesBlock.TeamTemplates}
        templates={teamTemplates}
        {...{ isLoading }}
      />
    </>
  );
};

const MessagingSubContent = ({
  templatesBlock,
  templates,
  isLoading,
}: {
  templatesBlock: TemplatesBlock;
  templates: {
    messagingTemplates: Array<MessagingTemplate>;
    hasNextPage: boolean;
    paginationProps?: any;
  };
  isLoading?: boolean;
}) => {
  const { messagingTemplates, hasNextPage, paginationProps } = templates || {};
  const {
    selectedTab: tabSelected,
    templateFunctions,
    isSmartEmail,
    activeBobject,
    actionsDisabled,
    company,
    leads,
    sidePeekEnabled,
    whatsappData,
    environment,
  } = usePlaybookFeed();

  const { t } = useTranslation();
  const { settings } = useActiveUserSettings();
  const userId = settings?.user?.id;
  const userRoles = settings?.user?.roles;
  const isAdminUser =
    userRoles?.includes(UserRole.GLOBAL_ADMIN) || userRoles?.includes(UserRole.ACCOUNT_ADMIN);
  const isSnippets = tabSelected === PlaybookTab.SNIPPETS;
  const { has, save } = useUserHelpers();
  const shouldShowEmailTooltip =
    tabSelected === PlaybookTab.EMAILS &&
    !has(UserHelperTooltipsKeys.BLANK_EMAIL_TOOLTIP) &&
    isSmartEmail;

  const {
    noResults,
    noTemplates,
    handleAddTemplateClick,
    handleOnClick,
    handleEmailModal,
  } = useMessagingContentActions(messagingTemplates);

  useEffect(() => {
    return () => {
      if (!noTemplates && !noResults && isSnippets) {
        save(ExtensionHelperKeys.SNIPPETS_TOOLTIP_BLOCK_SEEN_ONE_TIME);
      }
    };
  }, [templates?.messagingTemplates?.length, isSnippets]);

  const renderTemplate = (template: MessagingTemplate, showTooltip: boolean) => {
    const shouldShowSnippetsTooltip = isSmartEmail && isSnippets && showTooltip;

    if (shouldShowSnippetsTooltip) {
      return <SnippetsTooltipBlock hasBeenSeen />;
    }
    const userIsOwner = userId === template?.createdBy;
    const userCanEdit = userIsOwner || isAdminUser;

    const linkedInURL = getLinkedInURL(template?.id, company, leads, activeBobject);
    const buttonProps = getTemplateTypeButtons({
      template,
      tabSelected,
      linkedInURL,
      handleEmailModal,
      isSEE: isSmartEmail,
      userCanEdit,
      environment,
      templateFunctions,
      t,
      whatsappData,
    });

    return (
      <MessagingCard
        template={template}
        onClick={handleOnClick}
        key={template?.id}
        tabSelected={tabSelected}
        buttonProps={buttonProps}
        isSmartEmail={isSmartEmail}
        templateFunctions={templateFunctions}
        actionsDisabled={actionsDisabled}
        sidePeekEnabled={sidePeekEnabled}
      />
    );
  };

  const shouldShowSnippetsTooltip = isSmartEmail && isSnippets && !noTemplates && !noResults;
  const templateListDisplayVariables = {
    renderTemplate,
    handleAddTemplateClick,
  };

  return (
    <div>
      {shouldShowSnippetsTooltip && <SnippetsTooltipBlock />}
      <TemplateListDisplay
        messagingTemplates={messagingTemplates}
        shouldShowTooltip={
          templatesBlock === TemplatesBlock.SuggestedTemplates &&
          messagingTemplates?.length === 0 &&
          shouldShowEmailTooltip
        }
        templatesBlock={templatesBlock}
        paginationProps={{ hasNextPage, ...paginationProps }}
        noTemplatesProps={{
          isLoading,
          noResults,
          noTemplates,
        }}
        {...templateListDisplayVariables}
      />
    </div>
  );
};
