import { useAccountId } from '@bloobirds-it/hooks';
import { InsightType } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

const BOBJECT_FIELDS: Record<InsightType, string> = {
  DECISION: 'Picklist,MultiPicklist',
  GENERATION: 'Text,URL,Decimal,Number,Email,Phone',
};

export const useActivityFieldsInfo = (insightType: InsightType) => {
  const accountId = useAccountId();

  const key =
    '/entities/bobjectFields/search/findAllByAccountAndBobjectType_BobjectTypeModel_NameAndFieldType_NameIn';
  const { data: bobjectFields } = useSWR(
    insightType &&
      `${key}?bobjectTypeName=Activity&fieldTypeEnumName=${BOBJECT_FIELDS[insightType]}&account=/accounts/${accountId}`,
    url => api.get(url).then(res => res.data._embedded.bobjectFields),
  );

  const meetingTypeField = bobjectFields?.find(
    bf => bf.logicRole === 'ACTIVITY__MEETING_MAIN_TYPE',
  );

  const keyMeetingTypes = `/entities/bobjectPicklistFieldValues/search/findAllByBobjectField?bobjectField=/bobjectFields/${meetingTypeField?.id}`;

  const { data: meetingTypes } = useSWR(meetingTypeField && `${keyMeetingTypes}`, url =>
    api.get(url).then(res => res.data._embedded.bobjectPicklistFieldValues),
  );

  return {
    bobjectFields: (bobjectFields || []).filter(bf => bf.enabled && !bf.layoutReadOnly),
    meetingTypes: (meetingTypes || []).map(mt => ({ id: mt.id, name: mt.value, enabled: true })),
  };
};
