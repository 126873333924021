import { useEffect } from 'react';

import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  DataModelResponse,
  BobjectTypes,
  ExtensionLead,
  RelatedObject,
} from '@bloobirds-it/types';
import { getValueFromLogicRole } from '@bloobirds-it/utils';

import { useWhatsappMessages } from './hooks/useWhatsappMessages';
import styles from './whatsappDetail.module.css';
import { WhatsappDetailedActivity } from './whatsappDetailActivity';
import { WhatsappDetailFooter } from './whatsappDetailFooter';

export const WhatsappDetail = ({
  settings,
  activity,
  accountId,
  dataModel,
  activeBobject,
  activeRelatedObject,
  userId,
  channel,
  subscribeListeners,
}: {
  settings: any;
  activity: Bobject;
  activeBobject: ExtensionLead;
  activeRelatedObject: RelatedObject;
  accountId: string;
  dataModel: DataModelResponse;
  visibleFooter?: boolean;
  actionsDisabled?: boolean;
  userId?: string;
  channel: 'WHATSAPP' | 'WHATSAPP_BUSINESS';
  subscribeListeners: (type: BobjectTypes, mutator: () => void) => void;
}) => {
  const leadId = getValueFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.LEAD);

  const {
    messages,
    isLoading,
    totalMatching,
    mutate,
    saveNewMessage,
    updateMessageState,
  } = useWhatsappMessages(
    channel,
    leadId,
    accountId,
    subscribeListeners,
    activeRelatedObject?.compositeRelatedId,
  );

  // Clear messages when the component is unmounted
  useEffect(() => {
    return () => {
      mutate(undefined, false);
    };
  }, []);

  const visibleFooter = channel == 'WHATSAPP_BUSINESS';

  return (
    <div className={styles.container}>
      <div className={styles.activity_container}>
        <WhatsappDetailedActivity
          activity={activity}
          dataModel={dataModel}
          messagesPerDay={messages}
          isLoading={isLoading}
          totalMatching={totalMatching}
          fetchNextPage={mutate}
          activeBobject={activeBobject}
        />
      </div>
      {visibleFooter && (
        <WhatsappDetailFooter
          settings={settings}
          activity={activity}
          activeBobject={activeBobject}
          activeRelatedObject={activeRelatedObject}
          userId={userId}
          leadId={leadId}
          messages={messages}
          saveNewMessage={saveNewMessage}
          updateMessageState={updateMessageState}
        />
      )}
    </div>
  );
};
