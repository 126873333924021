export enum UserHelperKeys {
  // QUICK START GUIDE
  SEND_TO_SALSES_MODAL_FEEDBACK_STEP = 'SEND_TO_SALSES_MODAL_FEEDBACK_STEP',
  CHECK_OUT_YOUR_TARGET_MARKETS = 'CHECK_OUT_YOUR_TARGET_MARKETS',
  CHECK_OUT_YOUR_BUYER_PERSONAS = 'CHECK_OUT_YOUR_BUYER_PERSONAS',
  CREATE_YOUR_FIRST_CADENCE_STEP = 'CREATE_YOUR_FIRST_CADENCE_STEP',
  START_TASK_FROM_CADENCE = 'START_TASK_FROM_CADENCE',
  SEND_FIRST_AUTO_EMAIL = 'SEND_FIRST_AUTO_EMAIL',
  SAVE_NUMBER_SETTINGS = 'SAVE_NUMBER_SETTINGS',
  CREATE_YOUR_FIRST_PITCH = 'CREATE_YOUR_FIRST_PITCH',
  MARK_AS_DONE_ATTEMPT = 'MARK_AS_DONE_ATTEMPT',
  TAKE_TOUR_ON_OUTBOX = 'TAKE_TOUR_ON_OUTBOX',
  // ADMIN QUICK START GUIDE
  CREATE_FIRST_TARGET_MARKET = 'CREATE_FIRST_TARGET_MARKET',
  ADD_FIRST_BUYER_PERSONA = 'ADD_FIRST_BUYER_PERSONA',
  DEFINE_FIRST_SCENARIO = 'DEFINE_FIRST_SCENARIO',
  SET_COMPANY_FIELDS_TOUR = 'SET_COMPANY_FIELDS_TOUR',
  SET_LEAD_FIELDS_TOUR = 'SET_LEAD_FIELDS_TOUR',
  TAKE_TOUR_ON_GENERAL_SETTINGS = 'TAKE_TOUR_ON_GENERAL_SETTINGS',
  CONNECT_CRM_TOUR = 'CONNECT_CRM_TOUR',
  DEFINE_QQ = 'DEFINE_QQ',
  SET_UP_DASHBOARDS_TOUR = 'SET_UP_DASHBOARDS_TOUR',
  INVITE_TEAM = 'INVITE_TEAM',
  // OTO QUICK START GUIDE
  CONNECT_SALESFORCE = 'CONNECT_SALESFORCE',
  CONNECT_EMAIL = 'CONNECT_EMAIL',
  SET_TIMEZONE = 'SET_TIMEZONE',
  START_USING_SALESFORCE_ON_TOP_OF = 'START_USING_SALESFORCE_ON_TOP_OF',
  CHOOSE_LANGUAGE = 'CHOOSE_LANGUAGE',

  //SHARED QSG KEYS
  SET_UP_REMINDERS = 'SET_UP_REMINDERS',
  CHOOSE_DIALER = 'CHOOSE_DIALER',
  SET_YOUR_EMAIL_SIGNATURE = 'SET_YOUR_EMAIL_SIGNATURE',
  CREATE_FIRST_COMPANY = 'CREATE_FIRST_COMPANY',
  CREATE_FIRST_LEAD = 'CREATE_FIRST_LEAD',
  LAUNCH_YOUR_FIRST_CADENCE = 'LAUNCH_YOUR_FIRST_CADENCE',
  TAKE_TOUR_PROSPECT_TAB = 'TAKE_TOUR_PROSPECT_TAB',
  CALL_AND_REPORT_RESULT = 'CALL_AND_REPORT_RESULT',
  CREATE_LEAD_FROM_LINKEDIN = 'CREATE_LEAD_FROM_LINKEDIN',
  LINK_FIRST_MESSAGE_LINKEDIN = 'LINK_FIRST_MESSAGE_LINKEDIN',
  TAKE_TOUR_ON_INBOX = 'TAKE_TOUR_ON_INBOX',
  DOWNLOAD_CHROME_EXTENSION = 'DOWNLOAD_CHROME_EXTENSION',
  CREATE_FIRST_EMAIL_TEMPLATE = 'CREATE_FIRST_EMAIL_TEMPLATE',
  CREATE_YOUR_FIRST_CADENCE = 'CREATE_YOUR_FIRST_CADENCE',
  CONNECT_EMAIL_ACCOUNT = 'CONNECT_EMAIL_ACCOUNT',
  ENABLE_KPI_METRICS = 'ENABLE_KPI_METRICS',
  CREATE_FIRST_LIST = 'CREATE_FIRST_LIST',
  SEND_YOUR_FIRST_EMAIL = 'SEND_YOUR_FIRST_EMAIL',

  // REST
  COMPLETE_WELCOME_SCREEN = 'COMPLETE_WELCOME_SCREEN',
  SEEN_INACTIVE_DISCOVERY_TOOLTIP = 'SEEN_INACTIVE_DISCOVERY_TOOLTIP',
  NEW_MEETING_MODAL = 'NEW_MEETING_MODAL',
  NEW_INVITEES_SYNC = 'NEW_INVITEES_SYNC',
  PREVIEW_EMAIL_MODAL = 'PREVIEW_EMAIL_MODAL',
  DONT_SHOW_AGAIN_CREATED_USER_MODAL = 'DONT_SHOW_AGAIN_CREATED_USER_MODAL',
  DONT_SHOW_AGAIN_MEETING_FUNNEL_DATE_HINT = 'DONT_SHOW_AGAIN_MEETING_FUNNEL_DATE_HINT',
  SCHEDULE_NEXT_STEP_DATETIME_FILTER = 'SCHEDULE_NEXT_STEP_DATETIME_FILTER',
  WIZARDS_RECALL_DATETIME = 'WIZARDS_RECALL_DATETIME',
  SHOW_DISABLED_VALUES_FILTERS_DASHBOARDS = 'SHOW_DISABLED_VALUES_FILTERS_DASHBOARDS',
  SHOW_DISABLED_VALUES_FILTERS_REPORTS = 'SHOW_DISABLED_VALUES_FILTERS_REPORTS',
}

export enum UserHelperTooltipsKeys {
  WELCOME = 'WELCOME',
  TAB_BAR = 'TAB_BAR',
  TASKS = 'TASKS',
  NOTIFICATIONS = 'NOTIFICATIONS',
  MAIL_DISCOVERY_TOOLTIP = 'MAIL_DISCOVERY_TOOLTIP',
  ON_CADENCE_DISCOVERY_TOOLTIP = 'ON_CADENCE_DISCOVERY_TOOLTIP',
  COMPANIES_AND_LEADS_TOOLTIP = 'COMPANIES_AND_LEADS_TOOLTIP',
  START_TO_FINISH = 'START_TO_FINISH',
  ON_CADENCE_FILTERS = 'ON_CADENCE_FILTERS',
  START_TASKS = 'START_TASKS',
  OUTBOX_AUTOMATED_EMAILS = 'OUTBOX_AUTOMATED_EMAILS',
  OUTBOX_AUTOMATED_BULK = 'OUTBOX_AUTOMATED_BULK',
  OUTBOX_SCHEDULED_EMAILS = 'OUTBOX_SCHEDULED_EMAILS',
  OUTBOX_SCHEDULED_ACTIONS = 'OUTBOX_SCHEDULED_ACTIONS',
  OUTBOX_SCHEDULED_NO_EMAILS = 'OUTBOX_SCHEDULED_NO_EMAILS',
  OUTBOX_SCHEDULED_PAGE = 'OUTBOX_SCHEDULED_PAGE',
  INBOX_LEFT_SIDE_NAVIGATION = 'INBOX_LEFT_SIDE_NAVIGATION',
  INBOX_FILTERS = 'INBOX_FILTERS',
  INBOX_BULK_ACTIONS = 'INBOX_BULK_ACTIONS',
  INBOX_INDIVIDUAL_ACTIONS = 'INBOX_INDIVIDUAL_ACTIONS',
  INBOX_NO_TASKS = 'INBOX_NO_TASKS',
  DASHBOARDS_GENERAL = 'DASHBOARDS_GENERAL',
  DASHBOARDS_DATE_FILTER = 'DASHBOARDS_DATE_FILTER',
  DASHBOARDS_GROUP_BY = 'DASHBOARDS_GROUP_BY',
  DASHBOARDS_FILTERS = 'DASHBOARDS_FILTERS',
  DASHBOARDS_ACTIVITY = 'DASHBOARDS_ACTIVITY',
  GENERAL_SETTINGS_ASSIGNMENTS = 'GENERAL_SETTINGS_ASSIGNMENTS',
  GENERAL_SETTINGS_MEETINGS = 'GENERAL_SETTINGS_MEETINGS',
  GENERAL_SETTINGS_LEADS = 'GENERAL_SETTINGS_LEADS',
  SHOW_QSG_FILTER = 'SHOW_QSG_FILTER',
  SHOW_QSG_FILTER_INFO = 'SHOW_QSG_FILTER_INFO',
  HUBSPOT_SYNCING = 'HUBSPOT_SYNCING',
  HUBSPOT_LOGS = 'HUBSPOT_LOGS',
  NO_HUBSPOT_LOGS = 'NO_HUBSPOT_LOGS',
  HUBSPOT_OBJECTS_SYNCING = 'HUBSPOT_OBJECTS_SYNCING',
  HUBSPOT_USERS = 'HUBSPOT_USERS',
  HUBSPOT_FIELDS = 'HUBSPOT_FIELDS',
  SALESFORCE_SYNCING = 'SALESFORCE_SYNCING',
  SALESFORCE_LOGS = 'SALESFORCE_LOGS',
  SALESFORCE_OBJECTS_SYNCING = 'SALESFORCE_OBJECTS_SYNCING',
  SALESFORCE_USERS = 'SALESFORCE_USERS',
  SALESFORCE_FIELDS = 'SALESFORCE_FIELDS',
  NO_SALESFORCE_LOGS = 'NO_SALESFORCE_LOGS',
  FIELDS_CHOOSE_BOBJECT = 'FIELDS_CHOOSE_BOBJECT',
  FIELDS_CREATE = 'FIELDS_CREATE',
  FIELDS_PREVIEW_FORMS = 'FIELDS_PREVIEW_FORMS',
  SAVE_LIST = 'SAVE_LIST',
  CREATE_FIRST_LIST_FILTERS_COLUMNS = 'CREATE_FIRST_LIST_FILTERS_COLUMNS',
  COPILOT_ENABLED = 'COPILOT_ENABLED',
  BLANK_EMAIL_TOOLTIP = 'BLANK_EMAIL_TOOLTIP',
  SLOTS_TOOLTIP = 'SLOTS_TOOLTIP',
}

export enum ExtensionHelperKeys {
  EXT_BUBBLE_HOME_PAGE = 'BUBBLE_HOME_PAGE',
  EXT_SALESFORCE_CONTACTS = 'SALESFORCE_CONTACTS',
  CUSTOM_TASKS = 'CUSTOM_TASKS',
  PIPELINE_TAB_COMPANY_LAST_VISIT = 'PIPELINE_TAB_COMPANY_LAST_VISIT',
  PIPELINE_TAB_LEAD_LAST_VISIT = 'PIPELINE_TAB_LEAD_LAST_VISIT',
  PIPELINE_TAB_OPPORTUNITY_LAST_VISIT = 'PIPELINE_TAB_OPPORTUNITY_LAST_VISIT',
  PIPELINE_TAB_FIRST_VISIT = 'PIPELINE_TAB_FIRST_VISIT',
  SIDEPEEK_DISABLED = 'SIDEPEEK_DISABLED',
  TOUR_KEYS = 'TOUR_KEYS',
  TOUR_DONE = 'EXTENSION_TOOLTIP_TOUR_DONE',
  LEFT_BAR_TOOLTIP = 'LEFT_BAR_TOOLTIP',
  SEE_GENERAL_TOOLTIP = 'SEE_GENERAL_TOOLTIP',
  ACTIVITY_TIMELINE_TOOLTIP_BLOCK = 'ACTIVITY_TIMELINE_TOOLTIP_BLOCK',
  EMAIL_ACTION_TOOLTIP = 'EMAIL_ACTION_TOOLTIP',
  CREATE_TASKS_TOOLTIP = 'CREATE_TASKS_TOOLTIP',
  PLAYBOOK_SNIPPETS_TOOLTIP = 'PLAYBOOK_SNIPPETS_TOOLTIP',
  SIMILAR_DEALS_TOOLTIP = 'SIMILAR_DEALS_TOOLTIP',
  SNIPPETS_TOOLTIP_BLOCK_SEEN_ONE_TIME = 'SNIPPETS_TOOLTIP_BLOCK_SEEN_ONE_TIME',
  SNIPPETS_TOOLTIP_BLOCK_HIDDEN = 'SNIPPETS_TOOLTIP_BLOCK_HIDDEN',
  SIMILAR_DEALS_INFO_BANNER = 'SIMILAR_DEALS_INFO_BANNER',
  OPENED_GENERAL_SEARCH_BAR = 'OPENED_GENERAL_SEARCH_BAR',
  DONT_SHOW_GENERAL_SEARCH_BAR_BANNER = 'DONT_SHOW_GENERAL_SEARCH_BAR_BANNER',
}

export enum CustomUserHelperKeys {
  ALL_DEFAULT_TAB = 'ALL_DEFAULT_TAB',
  COMPANIES_AND_LEADS_DEFAULT_TAB = 'COMPANIESANDLEADS_DEFAULT_TAB',
  DELIVERED_DEFAULT_TAB = 'DELIVERED_DEFAULT_TAB',
  INACTIVE_DEFAULT_TAB = 'INACTIVE_DEFAULT_TAB',
  INACTIVE_SALES_DEFAULT_TAB = 'INACTIVE_SALES_DEFAULT_TAB',
  HAVE_SAVED_FIELDS_CUSTOM_ORDER = 'HAVE_SAVED_FIELDS_CUSTOM_ORDER',
  COPILOT_LANGUAGE = 'COPILOT_LANGUAGE',
}
