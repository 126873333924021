import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createToast, Icon, IconButton } from '@bloobirds-it/flamingo-ui';
import { useWhatsAppWindowManagement } from '@bloobirds-it/hooks/src';
import { AttachmentList, useAttachedFiles } from '@bloobirds-it/misc';
import { WhatsappTemplateSelector } from '@bloobirds-it/playbook';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  Bobject,
  LEAD_FIELDS_LOGIC_ROLE,
  UserPermission,
  ExtensionLead,
  RelatedObject,
} from '@bloobirds-it/types';
import {
  api,
  getFieldByLogicRole,
  getISODate,
  getReferencedBobject,
  getUserTimeZone,
  getValueFromLogicRole,
} from '@bloobirds-it/utils';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';

import { DetailsFooter } from '../../components/detailsFooter/detailsFooter';
import AutoresizingTextArea from './components/autoresizingTextArea';
import { WhatsappFileDropzone } from './components/whatsappFileDropzone';
import { formatDateWs, MessagesPerDay } from './hooks/useWhatsappMessages';
import styles from './whatsappDetail.module.css';

const sendMessage = async newMessage => {
  return await api.post('/messaging/whatsapp/send', newMessage);
};

export const WhatsappDetailFooter = ({
  settings,
  activity,
  activeBobject,
  activeRelatedObject,
  userId,
  leadId,
  messages,
  saveNewMessage,
  updateMessageState,
}: {
  settings: any;
  activity: Bobject;
  activeBobject: ExtensionLead;
  activeRelatedObject: RelatedObject;
  userId?: string;
  leadId: string;
  messages: MessagesPerDay;
  saveNewMessage: (newMessages: MessagesPerDay) => void;
  updateMessageState: (
    messageId: string,
    state: 'SENT' | 'PENDING' | 'FAILED',
    wid?: string,
  ) => void;
}) => {
  const { attachedFiles, removeAttachedFile, uploadAttachedFile } = useAttachedFiles();

  const isWhatsappAdmin = settings?.user?.permissions?.includes(
    UserPermission.WHATSAPP_BUSINESS_ADMIN,
  );
  const hasWhatsappPermission = settings?.user?.permissions?.includes(
    UserPermission.WHATSAPP_BUSINESS,
  );
  const hasEditAllPermission = settings?.user?.permissions?.includes(UserPermission.EDIT_ALL);
  const whatsAppWindowManagement = useWhatsAppWindowManagement(settings?.account?.id);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const timeZone = getUserTimeZone();
  const messageToSend = useRef('');
  const [isSending, setIsSending] = useState(false);
  const [defaultMessage, setDefaultMessage] = useState('');
  const [resetMessageCounter, setMessageCounter] = useState(0);

  const referenceBobject = getReferencedBobject(activity);
  const assignee =
    getFieldByLogicRole(
      referenceBobject,
      LEAD_FIELDS_LOGIC_ROLE.ASSIGNED_TO,
      // @ts-ignore
    )?.value || referenceBobject?.assignedTo;

  const assignedToActiveUser = assignee === userId;
  const companyId = getValueFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.COMPANY);
  const phoneTo = getValueFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.CALL_LEAD_PHONE_NUMBER,
  );
  const phoneFrom = getValueFromLogicRole(
    activity,
    ACTIVITY_FIELDS_LOGIC_ROLE.CALL_USER_PHONE_NUMBER,
  );
  const lastMessage = messages?.[Object.keys(messages)[0]]?.messages[0];

  let isInsideWindow = true;
  if (whatsAppWindowManagement) {
    const lastIncomingMessage = messages?.[Object.keys(messages)[0]]?.messages?.find(
      message => message?.direction === 'INCOMING',
    );
    if (lastIncomingMessage) {
      const lastIncomingDate = new Date(lastIncomingMessage.isoDate);
      const now = new Date();
      const hoursDifference = (now.getTime() - lastIncomingDate.getTime()) / (1000 * 60 * 60);
      isInsideWindow = hoursDifference <= 23.5;
    } else {
      isInsideWindow = false;
    }
  }

  const handleFilesAdded = files => {
    // Process the files here (e.g., uploading them and attaching to a message)
    if (messageToSend.current === '') {
      messageToSend.current = ' ';
    }
    uploadAttachedFile(Array.from(files), true);
  };

  const sendWhatsAppMessage = async () => {
    if (messageToSend.current != '' || attachedFiles?.length > 0) {
      setIsSending(true);

      // Datos del mensaje a enviar a la API
      const newMessageToSend = {
        from: phoneFrom,
        to: phoneTo,
        body: messageToSend.current,
        leadId: leadId,
        companyId: companyId,
        ...(activeRelatedObject && { relatedObjectId: activeRelatedObject.compositeRelatedId }),
        mediaIds: attachedFiles?.length > 0 ? attachedFiles.map(af => af.id) : null,
        markConversationAsReported: true,
      };

      // Datos optimistas que reflejan un nuevo mensaje en la lista.
      const isoDate = new Date().toISOString();
      const formattedDateKey = formatDateWs(isoDate, lang, timeZone, t);
      const newParseMessageId = `optimisticId-${uuid()}`;
      const hasMedia = attachedFiles?.length > 0;
      const newParseMessage: MessagesPerDay = {
        [getISODate(new Date())]: {
          formattedDate: formattedDateKey,
          messages: [
            {
              id: newParseMessageId,
              wid: null,
              direction: 'OUTGOING',
              body: messageToSend.current,
              media: hasMedia ? attachedFiles : null,
              isoDate: isoDate,
              hasTranscription: false,
              caseId: lastMessage?.caseId,
              caseNumber: lastMessage?.caseNumber,
              user: settings?.user?.name,
              state: 'PENDING',
            },
          ],
        },
      };

      try {
        // Guardar el mensaje en la lista de mensajes con estado PENDING
        saveNewMessage(newParseMessage);

        // Actualizar la lista de mensajes
        setDefaultMessage('');
        setMessageCounter(c => c + 1);
        messageToSend.current = '';
        setIsSending(false);

        for (const file of attachedFiles) {
          removeAttachedFile(file.id);
        }

        // Llamada a la API para enviar el mensaje
        const response = await sendMessage(newMessageToSend);
        const wid = response?.data;

        // Actualizar el mensaje a estado SENT
        updateMessageState(newParseMessageId, 'SENT', wid);
      } catch (error) {
        createToast({
          message: t('whatsapp.conversation.errorSending'),
          type: 'error',
        });
        setIsSending(false);
        updateMessageState(newParseMessageId, 'FAILED');
      }
    }
  };

  const hasPermission = isWhatsappAdmin || hasWhatsappPermission;
  const hasEditPermission = assignedToActiveUser || hasEditAllPermission;
  const hasPermissionToSend =
    isInsideWindow && hasPermission && hasEditPermission && phoneFrom != null && phoneTo != null;

  const placeholder = !hasPermission
    ? t('whatsapp.placeholder.noPermission')
    : !isInsideWindow
    ? t('whatsapp.placeholder.outsideWindow')
    : !hasEditPermission
    ? t('whatsapp.placeholder.noEditPermission')
    : t('whatsapp.placeholder.default');

  const whatsappTemplatesClasses = clsx(styles.whatsAppContainer, {
    [styles.whatsAppContainerDisabled]: !hasPermissionToSend || isSending,
  });

  return (
    <>
      <WhatsappFileDropzone onFilesAdded={handleFilesAdded} />
      {attachedFiles?.length > 0 && (
        <AttachmentList files={attachedFiles} onDelete={removeAttachedFile} />
      )}
      <DetailsFooter color="veryLightPeanut" withPadding={false}>
        <div className={styles.chatBox}>
          <WhatsappTemplateSelector
            leadId={activeBobject?.id?.value}
            setMessage={message => {
              const templateWithoutHtml = message
                .replace(/<(?:br|\/div|\/p)>/g, '\n')
                .replace(/<.*?>/g, '');
              setDefaultMessage(templateWithoutHtml);
              messageToSend.current = templateWithoutHtml;
            }}
            anchorProps={{
              className: whatsappTemplatesClasses,
              color: 'bloobirds',
            }}
          />
          <label className={styles.label} htmlFor="file-attachment">
            <Icon name="paperclip" size={20} color="bloobirds" />
            <input
              type="file"
              id="file-attachment"
              name="file-attachment"
              data-test="file-attachment"
              onChange={event => {
                if (messageToSend.current === '') {
                  messageToSend.current = ' ';
                }
                uploadAttachedFile(Array.from(event.target.files), true);
                event.target.value = null;
              }}
              className={styles.input}
              multiple={true}
            />
          </label>
          <AutoresizingTextArea
            hasPermissionToSend={hasPermissionToSend}
            isSending={isSending}
            defaultMessage={defaultMessage}
            setMessage={message => (messageToSend.current = message)}
            resetMessageCounter={resetMessageCounter}
            placeholder={placeholder}
          />
          <IconButton
            name="deliver"
            disabled={isSending || !hasPermissionToSend}
            color="bloobirds"
            size={24}
            onClick={sendWhatsAppMessage}
          />
        </div>
      </DetailsFooter>
    </>
  );
};
