import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckItem, Chip, Icon, MultiSelect, Skeleton } from '@bloobirds-it/flamingo-ui';
import { useActiveUserId, useActiveUserSettings, useUserTeams } from '@bloobirds-it/hooks';
import { TeamType, UserRole } from '@bloobirds-it/types';
import clsx from 'clsx';

import styles from './userTeamsFilter.module.css';

enum IdFilterTypes {
  TEAM = 'TEAM',
  USER = 'USER',
}

enum FilterActions {
  Select = 'SELECT',
  Unselect = 'UNSELECT',
}

export const UserFilterByTeams = ({
  value,
  onChange,
  selectProps,
}: {
  value: string[];
  onChange: (value: string[]) => void;
  selectProps?: {
    width?: string;
    borderless?: boolean;
    variant?: 'form' | 'filters';
  };
}) => {
  const activeUserId = useActiveUserId();
  const { settings } = useActiveUserSettings();
  const {
    teams,
    isLoadingTeams,
    getTeamsByManagerId,
    isManagerById,
    teamUsersAggregation,
    selectAllValue,
    teamlessUsers,
  } = useUserTeams();
  const teamIds = teams?.map(t => t?.id);
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedGroup, setSelectedGroup] = useState<IdFilterTypes>(IdFilterTypes.TEAM);

  const { t } = useTranslation('translation', { keyPrefix: 'leftBar.userTeamsFilter' });
  const userRoles = settings?.user?.roles;
  const isAdminUser =
    userRoles?.includes(UserRole.GLOBAL_ADMIN) || userRoles?.includes(UserRole.ACCOUNT_ADMIN);
  const isManager = isManagerById?.(activeUserId);
  const managerTeams = getTeamsByManagerId?.(activeUserId);
  const teamsToDisplay = isAdminUser ? teams : isManager ? managerTeams : undefined;
  const lastFilterValue = useRef<string[]>(value ?? []);

  const handleValueChange = (value: string[]) => {
    if (value?.length === teamUsersAggregation || value?.length === 0) {
      lastFilterValue.current = value;
      onChange(value);
    } else {
      let change: { id: string; type: IdFilterTypes; action: FilterActions };

      value.forEach(v => {
        if (lastFilterValue?.current && !lastFilterValue.current.includes(v)) {
          const idType = teamIds?.includes(v) ? IdFilterTypes.TEAM : IdFilterTypes.USER;
          change = { action: FilterActions.Select, id: v, type: idType };
        }
      });

      lastFilterValue.current.forEach(v => {
        if (!value?.includes(v)) {
          const idType = teamIds?.includes(v) ? IdFilterTypes.TEAM : IdFilterTypes.USER;
          change = { action: FilterActions.Unselect, id: v, type: idType };
        }
      });

      if (change) {
        if (change.type === IdFilterTypes.USER) {
          if (change.action === FilterActions.Select) {
            const teamsMatching = teams.filter(team =>
              team.teamUsers.every(user => value?.includes(user.userId)),
            );
            teamsMatching.forEach(teamId => {
              if (teamId && !value?.includes(teamId?.id)) {
                value.push(teamId?.id);
              }
            });
          }
          if (change.action === FilterActions.Unselect) {
            const teamsMatching = teams.filter(team =>
              team.teamUsers.some(user => user.userId === change.id),
            );
            teamsMatching.forEach((teamId: TeamType) => {
              if (teamId) {
                const allSelected = teamId.teamUsers.every(user => value?.includes(user.userId));
                if (!allSelected && value?.includes(teamId.id)) {
                  value = value.filter(id => id !== teamId.id);
                }
              }
            });
          }
        } else if (change.type === IdFilterTypes.TEAM) {
          if (change.action === FilterActions.Select) {
            const teamsMatching = teams.filter(team => team.id === change.id);
            teamsMatching.forEach(team => {
              if (team) {
                value.push(...team.teamUsers.map(user => user.userId), change.id);
              }
            });
          }
          if (change.action === FilterActions.Unselect) {
            const teamsMatching = teams.filter(team => team.id === change.id);
            teamsMatching.forEach(team => {
              if (team) {
                value = value.filter(id => !team.teamUsers.some(user => user.userId === id));
                value = value.filter(id => id !== change.id);
              }
            });
          }
        }
      }

      const cleanedUserIds = [...new Set(value)];
      lastFilterValue.current = cleanedUserIds;
      onChange(cleanedUserIds);
    }
  };
  const selectAll = () => {
    const cleanedUserIds =
      selectedGroup === IdFilterTypes.USER
        ? ([...selectAllValue, ...teamlessUsers.map(user => user.id)] as string[])
        : selectAllValue;
    const newValue = lastFilterValue.current.length >= cleanedUserIds.length ? [] : cleanedUserIds;
    lastFilterValue.current = newValue;
    onChange(newValue);
  };
  const displayedUserIds = new Set<string>();

  if (!isAdminUser && !managerTeams) {
    return null;
  }

  const isAllSelected =
    value?.length ===
    (selectedGroup === IdFilterTypes.USER
      ? selectAllValue?.length + teamlessUsers?.length
      : selectAllValue?.length);

  return isLoadingTeams ? (
    <Skeleton variant="rect" height={24} width={selectProps?.width ?? '100%'} />
  ) : (
    // @ts-ignore
    <MultiSelect
      size="small"
      variant="filters"
      width="100%"
      placeholder={t('userTeamsFilterPlaceholder')}
      value={value}
      onChange={value => handleValueChange(value)}
      onSearch={value => setSearchValue(value)}
      onClose={searchValue?.length > 0 ? () => setSearchValue('') : undefined}
      onBlur={searchValue?.length > 0 ? () => setSearchValue('') : undefined}
      onFocus={searchValue?.length > 0 ? () => setSearchValue('') : undefined}
      autocomplete
      sortByChecked={false}
      renderDisplayValue={value => {
        if (value?.length === teamUsersAggregation) return t('allTeams');
        if (value?.length === 1 && value[0] === activeUserId) return t('me');
        const selectedUsers = value?.filter(v => !teamIds?.includes(v))?.length;
        if (selectedUsers > 0) return t('teamsSelected', { count: selectedUsers });
        return '';
      }}
      {...selectProps}
    >
      {[
        <div className={styles['tab-buttons']} key="tab-buttons">
          <Chip
            size="small"
            selected={selectedGroup === IdFilterTypes.TEAM}
            variant="secondary"
            onClick={() => setSelectedGroup(IdFilterTypes.TEAM)}
          >
            {t('userTeamsFilterPlaceholder')}
          </Chip>
          <Chip
            size="small"
            variant="secondary"
            selected={selectedGroup === IdFilterTypes.USER}
            onClick={() => setSelectedGroup(IdFilterTypes.USER)}
          >
            {t('users')}
          </Chip>
        </div>,
        <p
          key="all-button"
          onClick={selectAll}
          className={clsx(styles['select-all-item'], { [styles.itemChecked]: isAllSelected })}
        >
          {t(selectedGroup === IdFilterTypes.TEAM ? 'allTeams' : 'allUsers')}
          {isAllSelected && <Icon name="check" color="bloobirds" size={12} />}
        </p>,
        ...(teamsToDisplay || []).map((team: TeamType) => {
          const teamChecked = value?.includes(team?.id);
          if (selectedGroup === IdFilterTypes.TEAM) {
            return (
              <CheckItem
                key={team?.id}
                value={team?.id}
                label={team?.name}
                icon={team.icon}
                iconColor={teamChecked ? 'bloobirds' : 'softPeanut'}
                className={clsx(styles['multiselect-items'], { [styles.itemChecked]: teamChecked })}
              >
                {team?.name}
              </CheckItem>
            );
          } else {
            return (team?.teamUsers || []).map(user => {
              // to prevent duplicates between team members
              if (displayedUserIds.has(user.userId)) {
                return null;
              }
              displayedUserIds.add(user.userId);

              return (
                <CheckItem
                  key={team?.id + '-' + user?.userId}
                  value={user?.userId}
                  label={user?.userName}
                  section={team?.name}
                  className={styles['multiselect-items']}
                >
                  {user.userName}
                </CheckItem>
              );
            });
          }
        }),
        //
        ...(selectedGroup === IdFilterTypes.USER ? teamlessUsers : []).map(user => (
          <CheckItem
            key={user.id}
            value={user.id}
            label={user.name}
            className={styles['multiselect-items']}
          >
            {user.name}
          </CheckItem>
        )),
      ]}
    </MultiSelect>
  );
};
