import React from 'react';

import { usePlayground } from '../playgroundPages';
import { PLAYGROUND_TABS } from '../types';
import GeneralPrompts from './generalPrompts';
import Insights from './insights';
import Lab from './lab';

const PlaygroundTabs = () => {
  const { selectedTab } = usePlayground();

  switch (selectedTab) {
    case PLAYGROUND_TABS.INSIGHTS:
      return <Insights />;
    case PLAYGROUND_TABS.LAB:
      return <Lab />;
    case PLAYGROUND_TABS.PROMPTS:
      return <GeneralPrompts />;
    default:
      return <div>PlaygroundTabs</div>;
  }
};

export default PlaygroundTabs;
