import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';
import { TFunction } from 'i18next';

import { ACTIVITY_TYPES_VALUES_LOGIC_ROLE } from './activity';

export const INSIGHT_TYPE_OPTIONS = (
  t: TFunction,
): {
  id: InsightType;
  name: string;
  enabled: boolean;
  color: ColorType;
  textColor: ColorType;
  icon: IconType;
  iconColor: ColorType;
}[] => [
  {
    id: 'DECISION',
    name: t('ai.insightType.decision'),
    enabled: true,
    color: 'lightPeanut',
    textColor: 'peanut',
    icon: 'book',
    iconColor: 'peanut',
  },
  {
    id: 'GENERATION',
    name: t('ai.insightType.generation'),
    enabled: true,
    color: 'lightPeanut',
    textColor: 'peanut',
    icon: 'suggestions',
    iconColor: 'peanut',
  },
];

export const INSIGHT_CATEGORY_OPTIONS = (
  t: TFunction,
): {
  id: InsightCategory;
  name: string;
  enabled: boolean;
}[] => [
  {
    id: 'MARKET_INTELLIGENCE',
    name: t('ai.insightCategory.MARKET_INTELLIGENCE'),
    enabled: true,
  },
  {
    id: 'QA',
    name: t('ai.insightCategory.QA'),
    enabled: true,
  },
  {
    id: 'REVENUE_INTELLIGENCE',
    name: t('ai.insightCategory.REVENUE_INTELLIGENCE'),
    enabled: true,
  },
  {
    id: 'TRAINING',
    name: t('ai.insightCategory.TRAINING'),
    enabled: true,
  },
  {
    id: 'FOLLOW_UP',
    name: t('ai.insightCategory.FOLLOW_UP'),
    enabled: true,
  },
];

export const INSIGHT_STATE_OPTIONS = (
  t: TFunction,
): {
  id: InsightState;
  name: string;
  enabled: boolean;
  color: ColorType;
  textColor: ColorType;
  icon: IconType;
}[] => [
  {
    id: InsightState.ACTIVE,
    name: t('ai.insightState.active'),
    enabled: true,
    color: 'lightBloobirds',
    textColor: 'bloobirds',
    icon: 'play',
  },
  {
    id: InsightState.CREATED,
    name: t('ai.insightState.created'),
    enabled: true,
    color: 'veryLightPeanut',
    textColor: 'peanut',
    icon: 'clock',
  },
  {
    id: InsightState.DRAFT,
    name: t('ai.insightState.draft'),
    enabled: true,
    color: 'veryLightPeanut',
    textColor: 'softPeanut',
    icon: 'noteAction',
  },
];
export const ACTIVITY_TYPE_OPTIONS = (
  t: TFunction,
): { id: CopilotActivityType | 'All'; name: string; enabled: boolean }[] => [
  { id: 'All', name: t('ai.insightActivity.All'), enabled: true },
  {
    id: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL,
    name: t(`ai.insightActivity.${ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL}`, { count: 1 }),
    enabled: true,
  },
  { id: 'Call', name: t('ai.insightActivity.Call', { count: 1 }), enabled: false },
  {
    id: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING,
    name: t(`ai.insightActivity.${ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING}`, { count: 1 }),
    enabled: true,
  },
  { id: 'Meeting', name: t('ai.insightActivity.Meeting', { count: 1 }), enabled: false },
  {
    id: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL,
    name: t(`ai.insightActivity.${ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL}`, { count: 1 }),
    enabled: true,
  },
  { id: 'Email', name: t('ai.insightActivity.Email', { count: 1 }), enabled: false },
  {
    id: ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN,
    name: t(`ai.insightActivity.${ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN}`, { count: 1 }),
    enabled: true,
  },
];

export interface TranscriptFragment {
  speaker: string;
  start: number;
  end: number;
  text: string;
  isLead: boolean;
}

export type TranscriptStatus =
  | 'CANT_GENERATE'
  | 'NOT_GENERATED'
  | 'GENERATED'
  | 'GENERATING'
  | 'NOT_FINISHED'
  | 'ERROR';

export interface ActivityTranscript {
  accountId: string;
  transcript: TranscriptFragment[];
  activityId: string;
  status: TranscriptStatus;
  error: string;
}

export interface ActivityAnalysis {
  accountId: string;
  activityId: string;
  transcriptId: string;
  questions: string[];
  competitors: string[];
  summary: string;
  value_fit: {
    positive_points: string[];
    negative_points: string[];
  };
  status: TranscriptStatus;
}

export type InsightType = 'GENERATION' | 'DECISION';
export const InsightType = {
  DECISION: 'DECISION' as InsightType,
  GENERATION: 'GENERATION' as InsightType,
};

export interface CoreInsightDefinition {
  id: string;
  accountId: string;
  insightType: InsightType;
  title: string;
  searchWords: string;
  activityField: string;
  versionIdentifier: string;
  version: string;
}

export interface PicklistField {
  name: string;
  values: { id: string; value: string }[];
  id: string;
  type: string;
}

export type InsightCategory =
  | 'QA'
  | 'REVENUE_INTELLIGENCE'
  | 'TRAINING'
  | 'MARKET_INTELLIGENCE'
  | 'FOLLOW_UP';

export type InsightStatus =
  | 'GENERATED'
  | 'GENERATING'
  | 'FAILED'
  | 'NOT_PROCESSED'
  | 'NO_RESULTS'
  | 'ERROR'
  | 'NOT_GENERATED';

export interface Insight {
  pk: string;
  insightId: string;
  accountId: string;
  activityId: string;
  transcriptId: string;
  insight_type: InsightType;
  extracted_values?: string[];
  generated_text?: string;
  choices?: string[];
  status?: InsightStatus;
}

export interface ActivityInsights {
  accountId: string;
  activityId: string;
  transcriptId: string;
  insights: Insight[];
  summary: string;
  status: TranscriptStatus;
}

export interface CRMFieldUpdate {
  name: string;
  value: string;
  entity: string;
}

export interface CRMUpdates {
  accountId: string;
  activityId: string;
  transcriptId: string;
  updates: CRMFieldUpdate[];
  status: TranscriptStatus;
}

export type InsightState = 'ACTIVE' | 'CREATED' | 'DRAFT';
export const InsightState = {
  ACTIVE: 'ACTIVE' as InsightState,
  CREATED: 'CREATED' as InsightState,
  DRAFT: 'DRAFT' as InsightState,
};
export type CopilotActivityType =
  // TODO: FE-806 Remove this retrocompatibility in new beta
  | 'Call'
  | 'Meeting'
  | 'Email'
  | ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL
  | ACTIVITY_TYPES_VALUES_LOGIC_ROLE.MEETING
  | ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL
  | ACTIVITY_TYPES_VALUES_LOGIC_ROLE.LINKEDIN;

export class CopilotInsight {
  id?: string = '';
  accountId?: string = '';
  insightType: InsightType;
  activityField: string | null;
  title: string;
  description: string;
  activityType: CopilotActivityType | 'All';
  activitySubtype: string | null;
  state: InsightState;
  createdAt: string;
  category: InsightCategory | null;

  constructor(props: Partial<CopilotInsight>) {
    const {
      id = '',
      accountId = '',
      insightType = InsightType.GENERATION,
      activityField = '',
      title = '',
      activityType = 'All',
      activitySubtype = 'All',
      state = 'DRAFT',
      createdAt,
      description = '',
      category = null,
    } = props;

    this.id = id;
    this.accountId = accountId;
    this.insightType = insightType;
    this.activityField = activityField;
    this.title = title;
    this.activityType = activityType;
    this.activitySubtype = activitySubtype;
    this.state = state;
    this.createdAt = createdAt;
    this.description = description;
    this.category = category;
  }

  getInsightForCreation(): Omit<CopilotInsight, 'id' | 'accountId' | 'createdAt'> {
    const { id, accountId, createdAt, ...rest } = this;
    //@ts-ignore
    return rest;
  }
}

export class CopilotInsightVersion {
  id?: string = '';
  accountId?: string = '';
  insightId: string;
  prompt: string;
  searchWords: any | null;
  temperature: number;
  isActiveVersion = false;
  version?: number = 0;
  createdAt: string;

  constructor(props: Partial<CopilotInsightVersion>) {
    const {
      id = '',
      prompt = '',
      accountId = '',
      searchWords = null,
      temperature = 0,
      version = 0,
      isActiveVersion = false,
      insightId,
      createdAt,
    } = props;
    this.insightId = insightId;
    this.id = id;
    this.accountId = accountId;
    this.prompt = prompt;
    this.searchWords = searchWords;
    this.temperature = temperature;
    this.version = version;
    this.isActiveVersion = isActiveVersion;
    this.createdAt = createdAt;
  }

  getInsightForPromptUpdate(): Omit<CopilotInsightVersion, 'getInsightForPromptUpdate'> {
    const { getInsightForPromptUpdate, ...rest } = this;
    //@ts-ignore
    return rest;
  }
}

export interface SummarizedResult {
  success: number;
  partial_success: number;
  failure: number;
  processing_error: number;
  without_expected_result: number;
  total: number;
}

export enum TestRunStatus {
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  NOT_STARTED = 'NOT_STARTED',
}

export interface TestRun {
  pk: string;
  account_id: string;
  test_set_id: string;
  created_at: string;
  insight_version_id: string;
  insight_id: string;
  results: Record<string, any>;
  status: TestRunStatus;
  summarized_results: SummarizedResult;
  version_number: number;
}
type Activity = {
  [key: string]: {
    [key: string]:
      | {
          pk: string;
          account_id: string;
          activity_id: string;
          result: string;
          insight_id: string;
        }
      | object;
  };
};

export type TestSet = {
  pk: string;
  account_id: string;
  name: string;
  created_at: string;
  activity_type: CopilotActivityType | 'All';
  activities: Activity;
};
