import { createContext, useContext, useEffect, useState } from 'react';

import { useActiveAccountId, useCopilotEnabled, useInterval } from '@bloobirds-it/hooks';
import {
  ACTIVITY_FIELDS_LOGIC_ROLE,
  ACTIVITY_TYPES_VALUES_LOGIC_ROLE,
  ActivityInsights,
  ActivityTranscript,
  Bobject,
  CRMUpdates,
} from '@bloobirds-it/types';
import { api, getFieldByLogicRole } from '@bloobirds-it/utils';

import { isNonTranscriptActivity, isTranscriptActivity } from '../utils';

type OverlayType = 'transcript' | 'analysis' | 'actions';

export interface CopilotActivityContext {
  overlay: OverlayType;
  setOverlay: (overlay: OverlayType) => void;
  hasOverlay: boolean;
  transcript: ActivityTranscript;
  activityInsights: ActivityInsights;
  crmUpdates: CRMUpdates;
  resetOverlay: () => void;
  generateAnalysis: () => void;
  regenerateUpdates: () => void;
  clearAllSubscriptions: () => void;
  reloadTranscript: () => Promise<void>;
  activity: Bobject;
  isTranscriptActivity: boolean;
}

const CopilotActivityContext = createContext<Partial<CopilotActivityContext>>({});

export const CopilotActivityContextProvider = ({
  activity,
  showInsights = true,
  children,
}: {
  activity: Bobject;
  showInsights?: boolean;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    setTranscript(undefined);
    resumeTranscriptInterval();
    setActivityInsights(undefined);
    resumeInsightsInterval();
    setCRMUpdates(undefined);
    resumeUpdatesInterval();
  }, [activity?.id?.objectId]);

  const accountId = useActiveAccountId();
  const isCopilotEnabled = useCopilotEnabled(accountId);

  const type = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE)
    ?.valueLogicRole as ACTIVITY_TYPES_VALUES_LOGIC_ROLE;

  const canGetTranscript = isTranscriptActivity(type);
  const isNoNTranscriptAct = isNonTranscriptActivity(type);

  const [overlay, setOverlay] = useState<OverlayType>();

  const hasOverlay = !!overlay;

  const [transcript, setTranscript] = useState<ActivityTranscript>();
  const [activityInsights, setActivityInsights] = useState<ActivityInsights>();
  const [crmUpdates, setCRMUpdates] = useState<CRMUpdates>();

  const [canGetInsights, setCanGetInsights] = useState<boolean>(
    isNoNTranscriptAct || transcript?.status === 'GENERATED',
  );

  useEffect(() => {
    if (transcript?.status === 'GENERATED' || isNoNTranscriptAct) {
      setCanGetInsights(true);
    }
  }, [transcript, activity]);

  const [
    isTranscriptIntervalCleared,
    cancelTranscriptInterval,
    resumeTranscriptInterval,
  ] = useInterval(
    () => {
      if (isCopilotEnabled && canGetTranscript && showInsights) {
        api
          .get<ActivityTranscript>(
            `/copilot/transcript/${
              type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL ? 'call' : 'meeting'
            }/${activity?.id.objectId}`,
          )
          .then(response => {
            setTranscript(response.data);
          });
      }
    },
    15000,
    activity?.id.objectId,
  );

  const generateAnalysis = () => {
    if (isCopilotEnabled && canGetInsights && showInsights) {
      api
        .get<ActivityInsights>(
          `/copilot/transcript/insights/${activity?.id.objectId}?regenerate=true`,
        )
        .then(response => {
          setActivityInsights(response.data);
          resumeInsightsInterval();
        });
    }
  };

  const [isInsightsIntervalCleared, cancelInsightsInterval, resumeInsightsInterval] = useInterval(
    () => {
      if (isCopilotEnabled && showInsights) {
        api
          .get<ActivityInsights>(`/copilot/transcript/insights/${activity?.id.objectId}`)
          .then(response => {
            setActivityInsights(response.data);
          });
      }
    },
    15000,
    activity?.id.objectId,
  );

  const [isUpdatesIntervalCleared, cancelUpdatesInterval, resumeUpdatesInterval] = useInterval(
    () => {
      if (isCopilotEnabled && showInsights) {
        api
          .get<CRMUpdates>(`/copilot/transcript/crm-updates/${activity?.id.objectId}`)
          .then(response => {
            setCRMUpdates(response.data);
          });
      }
    },
    15000,
    activity?.id.objectId,
  );

  useEffect(() => {
    if (
      (transcript?.status === 'GENERATED' || transcript?.status === 'ERROR') &&
      !isTranscriptIntervalCleared
    ) {
      cancelTranscriptInterval();
    }
  }, [transcript, cancelTranscriptInterval, isTranscriptIntervalCleared]);

  useEffect(() => {
    if (
      (activityInsights?.status === 'GENERATED' || activityInsights?.status === 'ERROR') &&
      !isInsightsIntervalCleared
    ) {
      cancelInsightsInterval();
    }
  }, [activityInsights, isInsightsIntervalCleared, cancelInsightsInterval]);

  useEffect(() => {
    if (
      (crmUpdates?.status === 'GENERATED' || crmUpdates?.status === 'ERROR') &&
      !isUpdatesIntervalCleared
    ) {
      cancelUpdatesInterval();
    }
  }, [crmUpdates, isUpdatesIntervalCleared, cancelUpdatesInterval]);

  const regenerateUpdates = () => {
    if (isCopilotEnabled && canGetInsights) {
      api
        .get<CRMUpdates>(`/copilot/transcript/crm-updates/${activity?.id.objectId}?regenerate=true`)
        .then(response => {
          setCRMUpdates(response.data);
          resumeUpdatesInterval();
        });
    }
  };

  const value: CopilotActivityContext = {
    hasOverlay,
    overlay,
    resetOverlay: () => setOverlay(undefined),
    transcript,
    activityInsights,
    crmUpdates,
    setOverlay,
    generateAnalysis,
    isTranscriptActivity: canGetTranscript,
    regenerateUpdates,
    clearAllSubscriptions: () => {
      cancelUpdatesInterval();
      cancelInsightsInterval();
      cancelTranscriptInterval();
    },
    activity,
    reloadTranscript: () =>
      api
        .get<ActivityTranscript>(
          `/copilot/transcript/${
            type === ACTIVITY_TYPES_VALUES_LOGIC_ROLE.CALL ? 'call' : 'meeting'
          }/${activity?.id.objectId}`,
        )
        .then(response => {
          setTranscript(response.data);
        }),
  };

  return (
    <CopilotActivityContext.Provider value={value}>{children}</CopilotActivityContext.Provider>
  );
};

export const useCopilotActivity = (): Partial<CopilotActivityContext> => {
  return useContext(CopilotActivityContext);
};
