import React from 'react';

import { Icon } from '@bloobirds-it/flamingo-ui';
import { useRouter } from '@bloobirds-it/hooks';
import clsx from 'clsx';

import { usePlayground } from '../../playgroundPages';
import { PlaygroundTab as PlaygroundTabType, TABS } from '../../types';
import styles from './sectionSelector.module.css';

const PlaygroundTab = ({
  tab,
  isSelected,
  selectTab,
}: {
  tab: typeof TABS[number];
  isSelected: boolean;
  selectTab: () => void;
}) => {
  const className = clsx(styles.tab, {
    [styles.selectedTab]: isSelected,
  });

  return (
    <div className={className} onClick={selectTab}>
      <Icon name={tab.icon} color={isSelected ? 'bloobirds' : 'softPeanut'} /> {tab.label}
    </div>
  );
};

export const SectionSelector = () => {
  const playgroundContext = usePlayground();
  const { selectedTab, setSelectedTab } = playgroundContext;
  const { push } = useRouter();
  const handleClick = (id: PlaygroundTabType) => {
    setSelectedTab(id);
    push(`/app/playground/${id}`);
  };
  return (
    <div className={styles.sectionSelector}>
      {TABS.map(tab => {
        const tabId = tab.id;
        return (
          <PlaygroundTab
            key={tabId}
            tab={tab}
            isSelected={selectedTab === tabId}
            selectTab={() => handleClick(tabId)}
          />
        );
      })}
    </div>
  );
};
