import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CircularBadge,
  Switch,
  Tag,
  Text,
  Button,
  IconButton,
  useToasts,
  Icon,
  Tooltip,
  Callout,
  Label,
  Spinner,
} from '@bloobirds-it/flamingo-ui';
import { useActiveUserSettings, useUserTeams } from '@bloobirds-it/hooks';
import { mutate } from 'swr';

import {
  EntityCard,
  EntityCardItem,
} from '../../../../../components/entityList/entityCard/entityCard';
import { useEntityActions } from '../../../../../hooks/entities/useEntityActions';
import { ConfirmDeleteModalLayout } from '../../../../../layouts/confirmDeleteModalLayout/confirmDeleteModalLayout';
import { JwtApi } from '../../../../../misc/api/jwt';
import { WebApi } from '../../../../../misc/api/web';
import { USER_PERMISSIONS } from '../../constants/users.constants';
import { useCreateEditUserModal } from '../../hooks/useCreateEditUserModal';
import styles from '../../styles/usersPage.module.css';

export const UserCard = ({ user }) => {
  const [viewAllTags, setViewAllTags] = useState();
  const { handleUpdateEntity, handleDeleteEntity } = useEntityActions();
  const { settings } = useActiveUserSettings();

  const editAllPermission = user?.permissions?.filter(
    perm => perm?.enumName === USER_PERMISSIONS.EDIT_ALL,
  );
  const permissionsWithoutEditAll = user?.permissions?.filter(
    perm => perm?.enumName !== USER_PERMISSIONS.EDIT_ALL,
  );
  const [temporarySwitch, setTemporarySwitch] = useState(user?.active);
  const { createToast } = useToasts();
  const { handleOpenCreateEditUserModal } = useCreateEditUserModal();
  const accountLanguage = settings?.account?.language;
  const isAccountAdmin = user?.roles?.some(role => role.name === 'Account Admin');
  const [confirmModalOpen, setConfirmModalOpen] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const { t } = useTranslation();
  const { managerOfTeams } = useUserTeams();
  const teamsOfManagement = managerOfTeams(user?.id);
  const [emailSentLoader, setEmailSentLoader] = useState(false);

  const handleToggleEnabled = active => {
    setTemporarySwitch(!temporarySwitch);
    handleUpdateEntity({
      id: user?.id,
      entityName: 'users',
      label: 'Users',
      body: { active },
      callback: () => {
        mutate('/users');
      },
    });
  };

  const requestPasswordRecovery = async () => {
    setEmailSentLoader(true);
    JwtApi.service.externalAction
      .requestRecoverPassword({
        email: user.email,
      })
      .then(response => {
        if (!response.ok) {
          throw new Error();
        }
        createToast({
          message: t('accountSettings.salesTeam.sendRecoveryInvitationSuccess'),
          type: 'success',
        });
        mutate('/users');
      })
      .catch(() => {
        createToast({
          message: t('accountSettings.salesTeam.sendRecoveryInvitationError'),
          type: 'error',
        });
        mutate('/users');
      })
      .finally(() => {
        setEmailSentLoader(false);
      });
  };

  const handleResendInvitation = () => {
    setEmailSentLoader(true);
    WebApi.search({
      path: `service/users/${user.id}/sendInvitation`,
    })
      .then(() => {
        createToast({
          message: t('accountSettings.salesTeam.resendInvitationSuccess'),
          type: 'success',
        });
        mutate('/users');
      })
      .catch(() => {
        createToast({
          message: t('accountSettings.salesTeam.resendInvitationError'),
          type: 'error',
        });
        mutate('/users');
      })
      .finally(() => {
        setEmailSentLoader(false);
      });
  };

  const handleDeleteUser = () => {
    setIsDeleting(true);
    handleDeleteEntity({
      id: user.id,
      entityName: 'users',
      label: 'User',
      callback: () => {
        setIsDeleting(false);
        createToast({
          message: t('accountSettings.salesTeam.userDeletedSuccess'),
          type: 'success',
        });
        mutate('/users');
      },
    });
  };

  return (
    <EntityCard>
      {confirmModalOpen && (
        <ConfirmDeleteModalLayout
          icon="person"
          assetLabel={'User'}
          isDeleting={isDeleting}
          handleDelete={handleDeleteUser}
          handleClose={() => setConfirmModalOpen(false)}
        >
          <Text size="m">
            {t('accountSettings.salesTeam.userDeleteWarning', { userName: user?.name })}
          </Text>
          <Text size="m" className={styles._delete__text}>
            {t('accountSettings.salesTeam.userDeleteConfirm')}
          </Text>
          {teamsOfManagement > 0 && (
            <div style={{ marginBottom: 16 }}>
              <Text size="m" weight="bold" color="softTomato">
                {t('accountSettings.salesTeam.teamsManagerOfWhich', { count: teamsOfManagement })}
              </Text>
            </div>
          )}
          <div className={styles._callout}>
            <Callout variant="alert" icon="alertTriangle">
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              {t('accountSettings.salesTeam.userDeleteCallout')}
            </Callout>
          </div>
        </ConfirmDeleteModalLayout>
      )}
      <EntityCardItem size="small">
        <CircularBadge size="medium" backgroundColor={user?.color} color="gray">
          {user?.shortname || 'U'}
        </CircularBadge>
      </EntityCardItem>
      <EntityCardItem>
        <Text size="s" inline>
          {user?.name}
        </Text>
        <Tooltip title={t('accountSettings.salesTeam.accountAdmin')} position="top">
          {isAccountAdmin && <Icon name="starChecked" color="softBanana" size={16} />}
        </Tooltip>
      </EntityCardItem>
      <EntityCardItem>{user?.employeeRole}</EntityCardItem>
      <EntityCardItem>{user?.email}</EntityCardItem>
      <EntityCardItem>{t(user?.language ?? accountLanguage)}</EntityCardItem>
      <EntityCardItem>
        {permissionsWithoutEditAll?.length > 0 && (
          <>
            {viewAllTags ? (
              <>
                {permissionsWithoutEditAll?.map(permission => (
                  <span className={styles._tag__element} key={permission?.name}>
                    <Tag>{permission?.name}</Tag>
                  </span>
                ))}
              </>
            ) : (
              <>
                <span className={styles._tag__element}>
                  <Tag>{permissionsWithoutEditAll[0]?.name}</Tag>
                </span>
                {permissionsWithoutEditAll?.length > 1 && (
                  <span onClick={() => setViewAllTags(true)}>
                    <Text className={styles._view_more__text} inline size="s">
                      +{permissionsWithoutEditAll?.length - 1}
                    </Text>
                  </span>
                )}
              </>
            )}
          </>
        )}
      </EntityCardItem>
      <EntityCardItem>
        {editAllPermission.length > 0
          ? editAllPermission?.map(perm => <Tag key={perm?.name}>{perm?.name}</Tag>)
          : null}
      </EntityCardItem>
      <EntityCardItem size="small" flexEnd>
        <div className={styles._actions__container}>
          {user.invitationStatus !== 'COMPLETED' && (
            <>
              {user.invitationExpired ? (
                <Button size="small" color="lightPeanut" disabled>
                  <Text color="softPeanut" size="s">
                    {t('accountSettings.salesTeam.expired')}
                  </Text>
                </Button>
              ) : (
                <Button color="softTangerine" size="small" disabled>
                  <Text color="white" size="s">
                    {t('accountSettings.salesTeam.pending')}
                  </Text>
                </Button>
              )}
            </>
          )}
          {user.invitationStatus !== 'COMPLETED' && (
            <Button
              className={styles['button-with-icon']}
              size="small"
              onClick={handleResendInvitation}
              variant="secondary"
            >
              {emailSentLoader && <Spinner size={16} />}
              {!emailSentLoader && <Icon name="deliver" size={16} />}
              {t('accountSettings.salesTeam.resend')}
            </Button>
          )}
          {user.invitationStatus === 'COMPLETED' &&
            (!user.blocked ? (
              <Switch checked={temporarySwitch} onChange={handleToggleEnabled} />
            ) : (
              <>
                <Tooltip title={t('accountSettings.salesTeam.blockedUserTooltip')} position="top">
                  <Label color="softTomato" hoverStyle={{ backgroundColor: 'softTomato' }}>
                    {t('accountSettings.salesTeam.blocked')}
                  </Label>
                </Tooltip>
                <Button
                  className={styles['button-with-icon']}
                  size="small"
                  onClick={requestPasswordRecovery}
                  variant="secondary"
                >
                  {emailSentLoader && <Spinner size={16} />}
                  {!emailSentLoader && <Icon name="deliver" size={16} />}
                  {t('accountSettings.salesTeam.sendRecovery')}
                </Button>
              </>
            ))}
          <IconButton
            name="edit"
            size={24}
            onClick={() => handleOpenCreateEditUserModal({ user })}
          />
          <Tooltip title={t('accountSettings.salesTeam.deleteButtonTooltip')} position="top">
            <IconButton name="trashFull" onClick={() => setConfirmModalOpen(true)} />
          </Tooltip>
        </div>
      </EntityCardItem>
    </EntityCard>
  );
};
