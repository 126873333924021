import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { UserPermission } from '@bloobirds-it/types';

import styles from '../../styles/usersPage.module.css';
import { CheckboxListItem } from './checkboxListItem';
import { SectionInterface } from './types/permissions';

export const PermissionsModalSection = ({
  sections,
  sectionTitle,
  selectAllHandle,
  values,
  updateValue,
  isVisibility = false,
}: {
  sections: SectionInterface[];
  sectionTitle: string;
  selectAllHandle?: string;
  values: string[];
  updateValue: (value: string[]) => void;
  isVisibility?: boolean;
}) => {
  const entriesIds = sections && Object.entries(sections)?.map(item => item[1]?.id);
  const [selectAll, setSelectAll] = useState(values?.length === entriesIds?.length);
  const { t } = useTranslation('translation', {
    keyPrefix:
      'accountSettings.salesTeam.createEditUserModal.userPermissions.permissionsModalSection',
  });

  useEffect(() => {
    setSelectAll(values?.length === entriesIds?.length);
  }, [values]);

  return (
    <div className={styles._section}>
      <div className={styles._last_section}>
        <div className={styles._section_header}>
          <Text size="m" color="peanut" weight="bold">
            {sectionTitle}
          </Text>
          <Text size="m" color="peanut" weight="bold">
            {t('allow')}
          </Text>
        </div>
        {selectAllHandle && (
          <CheckboxListItem
            size="small"
            value={selectAll}
            onChange={value => {
              updateValue(
                value
                  ? Object.entries(sections)?.map(field => {
                      return field[1]?.id;
                    })
                  : [],
              );
            }}
          >
            {selectAllHandle}
          </CheckboxListItem>
        )}
        {sections?.map(option => {
          if (!option?.enumName) {
            return <></>;
          }
          const fieldId = option?.id;
          return (
            <>
              {isVisibility && option?.enumName === UserPermission.NURTURING_TAB && (
                <div style={{ marginTop: 24 }}>
                  <Text size="s" color="softPeanut">
                    {t('extensionPermissions')}{' '}
                  </Text>
                </div>
              )}
              {isVisibility && option?.enumName === UserPermission.VIEW_CADENCES && (
                <div style={{ marginTop: 24 }}>
                  <Text size="s" color="softPeanut">
                    {t('webappPermissions')}
                  </Text>
                </div>
              )}
              <CheckboxListItem
                size="small"
                key={fieldId}
                value={values?.includes(fieldId)}
                onChange={value => {
                  updateValue(
                    value && !values?.includes(fieldId)
                      ? [...values, fieldId]
                      : values.filter(v => v !== fieldId),
                  );
                }}
              >
                {t(`permissions.${option?.enumName}`)}
              </CheckboxListItem>
            </>
          );
        })}
      </div>
    </div>
  );
};
