import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AssignedToSelector, BobjectSelector } from '@bloobirds-it/bobjects';
import {
  Button,
  DatePicker,
  Icon,
  IconButton,
  Item,
  Section,
  Select,
  Spinner,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import {
  useActiveAccountId,
  useCustomTasks,
  useDataModel,
  useMainRelatedUsageEnabled,
} from '@bloobirds-it/hooks';
import { useGetI18nSpacetime } from '@bloobirds-it/internationalization';
import { useBobjectRelations } from '@bloobirds-it/related-objects';
import {
  BobjectTypes,
  COMPANY_FIELDS_LOGIC_ROLE,
  DataModelResponse,
  LEAD_FIELDS_LOGIC_ROLE,
  MainBobjectTypes,
  RelatedObjectClass,
  SearchBobjectType,
} from '@bloobirds-it/types';
import { toSentenceCase } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';

import { PrioritySelector } from '../components/prioritySelector/prioritySelector';
import { TaskTypeSelector } from '../components/taskTypeSelector/taskTypeSelector';
import { useTaskForm } from '../hooks/useTaskForm';
import styles from './taskForm.module.css';

// @ts-ignore
interface TaskFormProps extends ReturnType<typeof useTaskForm> {
  modalId: string;
  isWebapp?: boolean;
  forceOpened?: boolean;
}

const transitionParams = {
  initial: { opacity: 0, height: 0 },
  animate: { opacity: 1, height: 'auto' },
  exit: { opacity: 0, height: 0 },
  transition: { duration: 0.3 },
};

const getSalesforceIdField = (bobject: SearchBobjectType, dataModel: DataModelResponse) => {
  const bobjectType = bobject?.bobjectType as MainBobjectTypes;
  if (bobjectType === BobjectTypes.Company) {
    return bobject.rawBobject.contents[
      dataModel.findFieldByLogicRole(COMPANY_FIELDS_LOGIC_ROLE.SALESFORCE_ACCOUNT_ID)?.id
    ];
  } else if (bobjectType === BobjectTypes.Lead) {
    const contactId = dataModel.findFieldByLogicRole(LEAD_FIELDS_LOGIC_ROLE.SALESFORCE_CONTACT_ID)
      ?.id;
    const leadId = dataModel.findFieldByLogicRole(LEAD_FIELDS_LOGIC_ROLE.SALESFORCE_LEAD_ID)?.id;
    return bobject.rawBobject.contents[contactId || leadId];
  }
};

export const TaskForm = (props: TaskFormProps) => {
  const {
    modalId,
    isEditionModal,
    handleDelete,
    title,
    titleOnChange,
    actionType,
    actionTypeOnChange,
    priority,
    priorityOnChange,
    taskDate,
    taskDateOnChange,
    datePickerVisible,
    datePickerRef,
    setDatePickerVisible,
    assignedToId,
    setAssignedToId,
    nameSelected,
    setNameSelected,
    onSubmit,
    formMethods,
    isWebapp = false,
    forceOpened,
    referenceBobject,
    referenceBobjectOnChange,
    relatedObject,
    relatedObjectOnChange,
  } = props;
  const dataModel = useDataModel();
  const { isSubmitting, isDirty, handleSubmit } = formMethods;
  const accountId = useActiveAccountId();

  const { customTasks } = useCustomTasks();
  const relatedManagementEnabled = useMainRelatedUsageEnabled(accountId);

  const { t } = useTranslation('translation', { keyPrefix: 'tasks.taskForm' });
  const { t: dateT } = useTranslation('translation', { keyPrefix: 'dates' });
  const { t: bobjectsT } = useTranslation('translation', { keyPrefix: 'bobjectTypes' });

  const handleChangeTaskType = value => {
    actionTypeOnChange(value);
    if (!['TASK', 'CALL', 'EMAIL'].includes(value)) {
      const customTaskDescription = customTasks?.find(task => task.id === value)?.description;
      if (customTaskDescription) {
        titleOnChange(customTaskDescription);
      }
    }
  };

  return (
    <div className={styles.content_container}>
      <div className={styles.editor}>
        <span className={styles.modal_title}>
          <div className={styles._bobject_type_selector}>
            <TaskTypeSelector
              value={actionType}
              onChange={handleChangeTaskType}
              isWebapp={isWebapp}
              forceOpened={forceOpened}
            />
            <PrioritySelector value={priority} onChange={priorityOnChange} />
          </div>
        </span>
        <span className={styles.divider} />
        <div className={styles.task_info}>
          <span className={styles.task_date}>
            <span className={styles.task_date_title}>
              <Icon name="clock" color="softPeanut" size={16} className={styles.mini_icon} />
              <Text size="xs" color="softPeanut">
                {t('dueDate')}
              </Text>
            </span>
            <DatePicker
              withTimePicker
              value={taskDate}
              openDefaultValue={taskDate ?? new Date()}
              onChange={taskDateOnChange}
              dropDownRef={datePickerRef}
              visible={datePickerVisible}
              setVisible={setDatePickerVisible}
              dropdownProps={{
                zIndex: 10000,
                anchor: (
                  <span onClick={() => setDatePickerVisible(true)} className={styles.date_button}>
                    <Text size="xs" color="bloobirds" weight="regular">
                      {useGetI18nSpacetime(taskDate).format(dateT('shortMonthFullDate'))}
                    </Text>
                  </span>
                ),
              }}
            />
          </span>
          <span className={styles.task_date}>
            <span className={styles.task_date_title}>
              <Icon name="personAdd" color="softPeanut" size={16} className={styles.mini_icon} />
              <Text size="xs" color="softPeanut">
                {t('assignedTo')}
              </Text>
            </span>
            <AssignedToSelector assignedToId={assignedToId} updateAssignedTo={setAssignedToId} />
          </span>
        </div>
        <>
          <span className={styles.divider} />
          <AnimatePresence>
            <div className={styles.task_info}>
              <span className={styles.task_date}>
                {nameSelected && (
                  <motion.span {...transitionParams} className={styles.task_date_title}>
                    <Icon
                      name="taskAction"
                      color="softPeanut"
                      size={16}
                      className={styles.mini_icon}
                    />
                    <Text size="xs" color="softPeanut">
                      {toSentenceCase(
                        bobjectsT(
                          (
                            referenceBobject?.bobjectType ||
                            referenceBobject?.id?.typeName ||
                            ''
                          ).toLowerCase(),
                        ),
                      )}
                      :
                    </Text>
                  </motion.span>
                )}
                <div className={styles.bobject_selector}>
                  <Tooltip title={!isWebapp && nameSelected} position="top">
                    <BobjectSelector
                      accountId={accountId}
                      selected={
                        (nameSelected ? (
                          <motion.div {...transitionParams}>
                            <Text size="xs" color="bloobirds" className={styles.nameSelected}>
                              {nameSelected}
                            </Text>
                          </motion.div>
                        ) : (
                          <Text size="xxs" color="bloobirds" className={styles.callToAction}>
                            <Icon name="plus" color="bloobirds" /> {t('linkCallToAction')}
                          </Text>
                        )) as any
                      }
                      id={modalId}
                      onBobjectChange={bobject => {
                        relatedObjectOnChange([]);
                        referenceBobjectOnChange({
                          ...bobject,
                          salesforceId: getSalesforceIdField(bobject, dataModel),
                        });
                        if (bobject?.bobjectType === BobjectTypes.Opportunity) {
                          setNameSelected(bobject?.name);
                        } else if (bobject?.bobjectType === BobjectTypes.Lead) {
                          setNameSelected(bobject?.fullName);
                        } else if (bobject?.bobjectType === BobjectTypes.Company) {
                          setNameSelected(bobject?.companyName);
                        }
                      }}
                      hideIcon
                    />
                  </Tooltip>
                </div>
              </span>
              {relatedManagementEnabled && (
                <RelatedSelector
                  visible={!!nameSelected}
                  referenceBobject={referenceBobject}
                  relatedObject={relatedObject}
                  setActiveRelated={relatedObjectOnChange}
                />
              )}
            </div>
          </AnimatePresence>
        </>
        <span className={styles.divider} />

        <textarea
          className={clsx(styles.textArea, { [styles.textArea_extended]: isWebapp })}
          value={title}
          placeholder={t('placeholder')}
          onChange={e => titleOnChange(e.target.value)}
          autoFocus
        />
      </div>
      <div>
        <div className={styles.bottom_bar}>
          <div style={{ display: 'flex', gap: 8, width: '100%', justifyContent: 'end' }}>
            {isEditionModal && (
              <Tooltip title={t('deleteTask')} position="top">
                <IconButton name="trashFull" size={22} onClick={handleDelete} />
              </Tooltip>
            )}
            <Button
              className={styles.add_task_button}
              size="small"
              onClick={() => {
                handleSubmit(() => onSubmit(isWebapp))();
              }}
              disabled={!isDirty || isSubmitting}
            >
              {isSubmitting ? (
                <Spinner name="loadingCircle" size={12} />
              ) : isEditionModal ? (
                t('saveTask')
              ) : (
                t('addTask')
              )}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const RelatedSelector = ({ visible, referenceBobject, relatedObject, setActiveRelated }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'tasks.taskForm' });
  const { t: selectT } = useTranslation('translation', {
    keyPrefix: 'relatedObjects.assignRelatedDropdown',
  });
  const { relations, loading } = useBobjectRelations({
    bobject: {
      ...referenceBobject,
      ...(!referenceBobject.salesforceId &&
        referenceBobject.salesforceContactId && {
          salesforceId: referenceBobject.salesforceContactId,
        }),
    },
    mainRelated: true,
  });
  const selectRef = useRef(null);

  const selectedRelatedObject =
    relations &&
    Object.values(relations)
      ?.flatMap(e => e)
      .find(r => r.compositeRelatedId === relatedObject);

  return (
    visible && (
      <motion.span
        {...transitionParams}
        className={styles.relatedWrapper}
        style={{ cursor: 'pointer' }}
      >
        <span className={styles.task_date_title}>
          <IconButton name="relations" color="softPeanut" size={16} className={styles.mini_icon} />
          <Text size="xs" color="softPeanut" className={styles.relatedToText}>
            {t('relatedTo')}
          </Text>
        </span>
        {selectedRelatedObject?.icon && (
          <Icon
            name={selectedRelatedObject?.icon}
            size={16}
            color="bloobirds"
            className={styles.mini_related_icon}
          />
        )}
        {relations && Object.values(relations).length ? (
          <Select
            key={relatedObject}
            anchorRef={selectRef}
            autocomplete
            size="small"
            width="100%"
            borderless
            transparent
            value={relatedObject}
            onChange={setActiveRelated}
            placeholder={selectT('edition.placeholder')}
            renderDisplayValue={() => selectedRelatedObject?.title}
          >
            <Item key="none" value={null} label={selectT('edition.none')}>
              {selectT('edition.none')}
            </Item>
            {!loading ? (
              Object.entries(relations)?.map(([key, values], index) => {
                return [
                  <Section key={key + '_' + index}>{key}</Section>,
                  ...(values?.map(rawR => {
                    const r = new RelatedObjectClass(rawR);
                    return (
                      <Item
                        key={r?.compositeRelatedId}
                        value={r?.compositeRelatedId}
                        label={r?.title}
                      >
                        {r.title}
                      </Item>
                    );
                  }) ?? []),
                ];
              })
            ) : (
              <Spinner name="loadingCircle" size={12} />
            )}
          </Select>
        ) : (
          <Text size="xs" color="softPeanut">
            {t('noRelations')}
          </Text>
        )}
      </motion.span>
    )
  );
};
