export const isElementLoaded = async selector => {
  let counter = 0;
  while (document.querySelector(selector) === null && counter <= 200) {
    await new Promise(resolve => {
      requestAnimationFrame(resolve);
    });
    counter = counter + 1;
  }
  return document.querySelector(selector);
};

type Options = {
  alwaysOpen: boolean; // Observar cambios en todo momento
  childList: boolean; // Observar cambios en los hijos directos del nodo
  subtree: boolean; // Observar cambios en todos los nodos descendientes del subárbol (WARNING: puede ser muy costoso)
  attributes: boolean; // Observar cambios en los atributos del nodo
  characterData: boolean; // Observar cambios en el contenido de texto (nodos de texto)
};

//TODO we should (most of the time) exclude the DOM mutations caused by the extension itself
export const waitForElement = (
  querySelector,
  resolve,
  timeout = 4000,
  options: Options = {
    alwaysOpen: false,
    childList: true,
    subtree: true,
    attributes: false,
    characterData: false,
  },
  reject = (message = `Element ${querySelector} not found within ${timeout} timeout`) => {
    console.warn(message);
  },
) => {
  return new Promise(() => {
    const element = document?.querySelector(querySelector);
    if (element) return resolve(element);

    const observer = new MutationObserver((mutationsList, observer) => {
      if (!mutationsList.some(mutation => mutation.addedNodes.length > 0)) return;
      mutationsList.forEach(mutation => {
        const targetNode = mutation.target as Element;
        // @ts-ignore
        if (mutation.addedNodes.length > 0) {
          if (targetNode?.id === 'bb-root') {
            return;
          }

          if (options.subtree) {
            const element = document?.querySelector(querySelector);
            if (element) {
              if (!options.alwaysOpen) {
                observer.disconnect();
              }
              resolve(element);
            }
          } else {
            mutation.addedNodes.forEach((node: Element) => {
              if (node?.id === 'bb-root') {
                return;
              }

              let element;
              if (node?.id?.includes(querySelector)) {
                element = node;
              } else {
                element = node?.querySelector(querySelector);
              }

              if (element) {
                if (!options.alwaysOpen) {
                  observer.disconnect();
                }
                resolve(element);
              }
            });
          }
        }
      });
    });

    // Start observing the document for changes.
    observer.observe(document.body, options);

    // Optional timeout to reject the promise if the element isn't found after a certain time
    if (timeout > 0) {
      setTimeout(() => {
        observer.disconnect();
        reject();
      }, timeout); // Adjust this timeout as needed
    }
  });
};
