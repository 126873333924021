// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sendToPlaygroundModal-module__content___JGLfX {\n  padding: 32px 24px\n}\n\n.sendToPlaygroundModal-module__checking_activities___8mwCT {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.sendToPlaygroundModal-module__name_input___tpLbp {\n  padding-top: 32px;\n}\n", "",{"version":3,"sources":["webpack://./src/js/components/sendToPlaygroundModal/sendToPlaygroundModal.module.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".content {\n  padding: 32px 24px\n}\n\n.checking_activities {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.name_input {\n  padding-top: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "sendToPlaygroundModal-module__content___JGLfX",
	"checking_activities": "sendToPlaygroundModal-module__checking_activities___8mwCT",
	"name_input": "sendToPlaygroundModal-module__name_input___tpLbp"
};
export default ___CSS_LOADER_EXPORT___;
