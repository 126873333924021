import React, { useState } from 'react';

import { Icon, IconButton } from '@bloobirds-it/flamingo-ui';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../../playgroundPages.module.css';
import { getResult } from './utils';

const ResultCell = ({
  hasError,
  result,
  insightType,
  activityResults,
  selectedRunId,
  testSetId,
}: {
  hasError: boolean;
  result: any;
  insightType: string;
  activityResults: any;
  selectedRunId: string;
  testSetId: string;
}) => {
  const [resultTag, setResultTag] = useState();

  if (resultTag) {
    return getResult(resultTag);
  }
  if (hasError) {
    return (
      <div className={styles.testRun_activities_resultFeedback}>
        <Icon name="cross" color="tomato" />
      </div>
    );
  }
  if (insightType === 'DECISION') {
    return result;
  }
  if (activityResults?.result_tag && activityResults?.result_tag !== 'WITHOUT_EXPECTED_RESULT') {
    return result;
  }
  function handleFeedback(feedback) {
    api
      .patch(
        `/copilot/playground/test-run/${selectedRunId}/${activityResults?.activity_id}/feedback`,
        {
          feedback,
        },
      )
      .then(response => {
        mutate(`/api/test-runs/${testSetId}`);
        if (response?.data?.results) {
          setResultTag(response?.data?.results[activityResults?.activity_id]?.result_tag);
        }
      });
  }

  return (
    <div className={styles.testRun_activities_resultFeedback}>
      <IconButton
        className={styles.thumbIcon}
        name="thumbsDown"
        size={22}
        color="peanut"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleFeedback('FAILURE');
        }}
      />
      <IconButton
        className={styles.thumbIcon}
        name="thumbsUp"
        size={22}
        color="peanut"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          handleFeedback('SUCCESS');
        }}
      />
    </div>
  );
};

export default ResultCell;
