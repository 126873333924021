import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Icon,
  Input,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Text,
  Button,
  ModalFooter,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { useEmailConnections } from '@bloobirds-it/hooks';

import { isEmail } from '../../misc/utils';
import { EmailConnection } from '../../typings/connections';
import { useUserSettings } from '../userPermissions/hooks';
import styles from './addAliasModal.module.css';

export const AddAliasModal = ({
  nylasAccount,
  onClose,
}: {
  nylasAccount: EmailConnection;
  onClose: () => void;
}) => {
  const { addAlias } = useEmailConnections();
  const settings = useUserSettings();
  const [emailAlias, setEmailAlias] = useState<string>();
  const [error, setError] = useState<boolean>(false);
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'userSettingsWebapp.addAliasModal' });

  const handleAddAlias = () => {
    addAlias({
      account: '/accounts/' + settings?.account?.id,
      emailAlias: emailAlias,
      nylasUserAccount: '/nylasUserAccounts/' + nylasAccount?.id,
    })
      .then(() => {
        onClose();
        createToast({ message: t('toasts.success'), type: 'success' });
      })
      .catch(() => {
        createToast({
          message: t('toasts.error'),
          type: 'error',
        });
      });
  };

  useEffect(() => {
    if (emailAlias?.length > 0 && !isEmail(emailAlias)) {
      setError(true);
    } else {
      setError(false);
    }
  }, [emailAlias]);

  return (
    <Modal open onClose={onClose} width={640}>
      <ModalHeader size="small">
        <ModalTitle className={styles.modalTitle}>
          <Icon name="mail" size={16} />
          <Text size="m" htmlTag="span">
            {t('title')}
          </Text>
        </ModalTitle>
        <ModalCloseIcon onClick={onClose} />
      </ModalHeader>
      <ModalContent>
        <Text size="m" className={styles.text}>
          {t('contentHeader')}
          <Text size="m" weight="bold" htmlTag="span">
            {nylasAccount?.email}
          </Text>
        </Text>
        <Text size="m" className={styles.text}>
          <Trans
            i18nKey="userSettingsWebapp.addAliasModal.content"
            components={[
              <a
                key="0"
                href="https://support.google.com/a/answer/33327?product_name=UnuFlow&hl=en&visit_id=637788702669262869-2616677116&rd=1&src=supportwidget0&hl=en"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              ></a>,
              <a
                key="1"
                href="https://support.microsoft.com/en-us/office/add-or-remove-an-email-alias-in-outlook-com-459b1989-356d-40fa-a689-8f285b13f1f2"
                target="_blank"
                rel="noreferrer"
                className={styles.link}
              ></a>,
            ]}
          />
        </Text>
        <Input
          name="alias"
          placeholder="Email alias"
          width="100%"
          error={error && t('validation')}
          onChange={setEmailAlias}
        />
        <Text size="xs" color="softPeanut" className={styles.text}>
          {t('footer')}
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button disabled={error || !emailAlias} onClick={handleAddAlias}>
          {t('confirm')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
