import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  IconButton,
  Input,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  Text,
  useHover,
  Button,
  useToasts,
} from '@bloobirds-it/flamingo-ui';
import { TestSet } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';
import { ActivityTypeIcon } from '../activityTypeIcon/activityTypeIcon';

const ConfirmationModal = ({
  onConfirm,
  onClose,
}: {
  onConfirm: () => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.testSet.confirmDelete' });
  const { t: tCommon } = useTranslation();

  return (
    <Modal width={600} open onClose={onClose}>
      <ModalHeader variant="primary" size="small" color="lightestPurple">
        <ModalTitle size="small" icon="trashFull">
          {t('title')}
        </ModalTitle>
        <ModalCloseIcon color="black" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.delete_test_set_modalContent}>
        <Text size="m" align="center">
          <Trans t={t} i18nKey="subtitle" />
        </Text>
        <Text size="m" align="center">
          {t('description')}
        </Text>
        <Text size="m" align="center">
          <b>{t('question')}</b>
        </Text>
      </ModalContent>
      <ModalFooter>
        <Button variant="tertiary" onClick={onClose} color="softPurple">
          {tCommon('common.back')}
        </Button>
        <Button variant="primary" color="tomato" onClick={onConfirm}>
          {tCommon('common.delete')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const TestSetCard = ({
  testSet,
  isSelected,
  handleClick,
}: {
  testSet: TestSet;
  isSelected: boolean;
  handleClick: () => void;
}) => {
  const [ref, isHovering] = useHover();
  const [mode, setMode] = useState({ edit: false, delete: false });
  const [name, setName] = useState(testSet.name);
  const { createToast } = useToasts();
  const { t } = useTranslation('ai.playground');
  const { t: baseT } = useTranslation();

  const classNames = clsx(styles.testSets_testSet, {
    [styles.active]: isSelected,
  });

  const hoverButtonsClassNames = clsx(styles.testSets_testSetHoverButtons, {
    [styles.active]: isSelected,
  });

  const handleUpdateTestSet = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ): Promise<void> => {
    e.stopPropagation();
    try {
      await api.patch(`/copilot/playground/test-set/${testSet.pk}`, { name });
      setMode({ edit: false, delete: false });
    } catch (error) {
      createToast({ type: 'error', message: t('updateNameError') });
    }
  };

  const handleDeleteTestSet = async (pk: string) => {
    await api.delete(`/copilot/playground/test-set/${pk}`);
  };

  return (
    <>
      <div ref={ref} key={testSet.pk} className={classNames} onClick={handleClick}>
        {!mode.edit ? (
          <>
            <Text className={styles.testSets_testSetName} size="s" color="peanut" weight="heavy">
              {name}
            </Text>
            <div className={styles.testSets_testSetInfo}>
              <ActivityTypeIcon activityType={testSet.activity_type} />
              <Text size="xs" color="peanut">
                {Object.keys(testSet.activities).length || 0}{' '}
                {baseT(`ai.insightActivity.${testSet.activity_type}`, { count: 2 })}
              </Text>
            </div>
          </>
        ) : (
          <div className={styles.inputContainer}>
            <Input
              size="small"
              type="text"
              width="100%"
              value={name}
              onChange={value => setName(value)}
              color="purple"
            />
            <IconButton
              className={styles.testSets_testSetIcon}
              name="check"
              size={16}
              color="melon"
              onClick={e => {
                handleUpdateTestSet(e);
              }}
            />
            <IconButton
              className={styles.testSets_testSetIcon}
              name="cross"
              size={16}
              color="tomato"
              onClick={e => {
                e.stopPropagation();
                setName(testSet.name);
                setMode({ edit: false, delete: false });
              }}
            />
          </div>
        )}
        {isHovering && !mode.edit && (
          <div className={hoverButtonsClassNames}>
            <IconButton
              className={styles.testSets_testSetIcon}
              name="edit"
              size={16}
              color="softPurple"
              onClick={e => {
                e.stopPropagation();
                setMode({ edit: true, delete: false });
              }}
            />
            <IconButton
              className={styles.testSets_testSetIcon}
              name="trashFull"
              size={16}
              color="softPurple"
              onClick={e => {
                e.stopPropagation();
                setMode({ edit: false, delete: true });
              }}
            />
          </div>
        )}
      </div>
      {mode.delete && (
        <ConfirmationModal
          onConfirm={async () => {
            setMode({ edit: false, delete: false });
            await handleDeleteTestSet(testSet.pk);
            mutate('/api/test-sets');
          }}
          onClose={() => setMode({ edit: false, delete: false })}
        />
      )}
    </>
  );
};

export default TestSetCard;
