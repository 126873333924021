import React from 'react';

import styles from '../playgroundPages.module.css';

const GeneralPrompts = () => {
  return (
    <div className={styles.playground_content}>
      <div style={{ width: '900px' }}>generalPrompts</div>
    </div>
  );
};

export default GeneralPrompts;
