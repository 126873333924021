import React, { useEffect, useRef, useState } from 'react';

import { IconButton, Spinner } from '@bloobirds-it/flamingo-ui';
import WaveSurfer from 'wavesurfer.js';

import { getSignedCallRecordingUrl } from '../../../../aiAnalysisPages/useActivityAnalysis';
import styles from '../../../playgroundPages.module.css';

const SoundWave = ({ id, activityAudio }: { id: string; activityAudio: any }) => {
  const waveRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [src, setSrc] = useState(null);

  const waveId = `waveform-${id}`;

  useEffect(() => {
    if (activityAudio?.recordCall) {
      getSignedCallRecordingUrl(activityAudio.recordCall).then(res => setSrc(res));
    }
  }, [activityAudio]);

  useEffect(() => {
    waveRef.current = WaveSurfer.create({
      container: `#${waveId}`,
      waveColor: '#94A5B4',
      progressColor: '#464F57',
      barWidth: 4,
      barRadius: 4,
    });

    // @ts-ignore
    waveRef.current?.on('ready', () => {
      setIsLoading(false);
    });

    // @ts-ignore
    waveRef.current?.on('error', function (e) {
      console.warn(e);
    });

    if (src) {
      fetch(src)
        .then(res => res.blob())
        .then(blob => {
          if (blob && blob?.size !== 0) {
            // @ts-ignore
            waveRef.current?.loadBlob(blob);
          }
        });
    }

    return () => {
      // @ts-ignore
      waveRef.current.destroy();
    };
  }, [src]);

  const buttonProps = isPlaying
    ? {
        name: 'pause',
        onClick: () => {
          setIsPlaying(false);
          // @ts-ignore
          waveRef?.current?.pause();
        },
      }
    : {
        name: 'play',
        onClick: () => {
          setIsPlaying(true);
          // @ts-ignore
          waveRef?.current?.play();
        },
      };

  return (
    <>
      <div id={waveId} style={{ display: isLoading ? 'hidden' : '' }} />
      {isLoading ? (
        <div className={styles.loader}>
          <Spinner name="loadingCircle" />
        </div>
      ) : (
        <div className={styles.controls}>
          {/*@ts-ignore*/}
          <IconButton color="#1A1A1A" {...buttonProps} size={20} />
        </div>
      )}
    </>
  );
};

export default SoundWave;
